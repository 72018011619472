<template>
  <div id="design-fonts">
    <h3>Шрифты</h3>

    <p>В качестве фирменного шрифта «АВТОМОЁ» выбрана гарнитура DINCyr. Она хорошо подходит как для текстового, так и для акцидентного набора. Этот шрифт необходимо использовать в дизайне и верстке всех материалов группы компаний, а так же в наружном и внутреннем оформлении.</p>

    <div class="fonts-box">
      <div>
        Обычное начертание (DINCyr-Regular) предназначено для набора текстового массива
      </div>
      <img src="@/assets/components/design/fonts/DINCyr-Regular.png" alt="Обычное начертание"/>

      <div>
        Полужирное начертание (DINCyr-Bold) предназначено для набора заголовков
      </div>
      <img src="@/assets/components/design/fonts/DINCyr-Bold.png" alt="Полужирное начертание"/>

      <div>
        Жирное начертание (DINCyr-Black) предназначено для слоганов, акцидентных надписей
      </div>
      <img src="@/assets/components/design/fonts/DINCyr-Black.png" alt="Жирное начертание"/>
    </div>

    <a href="/zips/66_Fonts.zip">
      <img src="@/assets/components/design/fonts/download.png" alt="Скачать шрифты">
    </a>

  </div>
</template>

<script>
export default {
  name: "DesignFonts"
}
</script>

<style lang="scss">
#design-fonts {
  width: 100%;
  margin-top: 95px;

  h3 {

  }

  p {
    width: 100%;
    padding-right: 30%;
  }

  .fonts-box {
    width: 100%;
    margin-top: 35px;
    margin-bottom: 65px;
    padding-right: 25%;
    display: grid;
    grid-auto-flow: column;
    grid-template-rows: max-content max-content;
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: 35px;
    grid-column-gap: 35px;
  }
}
</style>
