<template>
  <div id="jumbotron">
    <img class="logo" src="@/assets/components/jumbotron/logo.png" />
    <section>
      <div class="slogan">
        <div>
          <span>РАБОТАЕМ С ЛЮДЬМИ</span>,<br />
          <span>А НЕ С ЗАПЧАСТЯМИ</span>
        </div>

      </div>
      <div class="menu">
        <ul>
          <li v-scroll-to="{el: '#brand', duration: 800, easing: 'easeInOutQuad', offset: -200}" class="header">БРЕНД</li>
          <li v-scroll-to="{el: '#brand-mission', duration: 800, easing: 'easeInOutQuad', offset: -200}">Миссия</li>
          <li v-scroll-to="{el: '#values', duration: 800, easing: 'easeInOutQuad', offset: -200}">Ценности</li>
          <li v-scroll-to="{el: '#brand-character', duration: 800, easing: 'easeInOutQuad', offset: -200}">Характер бренда</li>
        </ul>
        <ul>
          <li v-scroll-to="{el: '#audience', duration: 900, easing: 'easeInOutQuad', offset: -200}" class="header">АУДИТОРИЯ</li>
          <li v-scroll-to="{el: '#audience-segments', duration: 900, easing: 'easeInOutQuad', offset: -200}">Сегменты</li>
          <li v-scroll-to="{el: '#audience-consumption-moments', duration: 900, easing: 'easeInOutQuad', offset: -200}">Моменты потребления</li>
        </ul>
        <ul>
          <li v-scroll-to="{el: '#interaction', duration: 1000, easing: 'easeInOutQuad', offset: -200}" class="header">ВЗАИМОДЕЙСТВИЕ</li>
          <li v-scroll-to="{el: '#interaction-partnership', duration: 1000, easing: 'easeInOutQuad', offset: -200}">Партнерство</li>
          <li v-scroll-to="{el: '#interaction-brand-names', duration: 1000, easing: 'easeInOutQuad', offset: -200}">Названия брендов</li>
          <li v-scroll-to="{el: '#interaction-formulations', duration: 1000, easing: 'easeInOutQuad', offset: -200}">Формулировки</li>
          <li v-scroll-to="{el: '#interaction-communication', duration: 1100, easing: 'easeInOutQuad', offset: -200}">Общение</li>
          <li v-scroll-to="{el: '#interaction-promotions', duration: 1100, easing: 'easeInOutQuad', offset: -200}">Акции</li>
        </ul>
        <ul>
          <li v-scroll-to="{el: '#design', duration: 1500, easing: 'easeInOutQuad', offset: -200}" class="header">ДИЗАЙН</li>
          <li v-scroll-to="{el: '#design-logos', duration: 1500, easing: 'easeInOutQuad', offset: -200}">Логотипы</li>
          <li v-scroll-to="{el: '#design-colors', duration: 1600, easing: 'easeInOutQuad', offset: -200}">Цвета</li>
          <li v-scroll-to="{el: '#design-fonts', duration: 1600, easing: 'easeInOutQuad', offset: -200}">Шрифты</li>
          <li v-scroll-to="{el: '#design-pattern', duration: 1700, easing: 'easeInOutQuad', offset: -200}">Паттерн</li>
          <li v-scroll-to="{el: '#design-brand-character', duration: 1700, easing: 'easeInOutQuad', offset: -200}">Персонаж бренда</li>
          <li v-scroll-to="{el: '#design-layouts', duration: 1700, easing: 'easeInOutQuad', offset: -200}">Макеты</li>
        </ul>
        <ul>
          <li v-scroll-to="{el: '#corporate-style', duration: 2000, easing: 'easeInOutQuad', offset: -200}" class="header">КОРПОРАТИВНЫЙ СТИЛЬ</li>
          <li v-scroll-to="{el: '#corporate-style-stores', duration: 2000, easing: 'easeInOutQuad', offset: -200}">Оформление магазинов</li>
          <li v-scroll-to="{el: '#corporate-style-clothing', duration: 2200, easing: 'easeInOutQuad', offset: -200}">Корпоративная одежда</li>
        </ul>
      </div>
    </section>
    <div class="arrow">
      <span></span>
      <span></span>
      <span></span>
    </div>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Jumbotron",
}
</script>

<style lang="scss">
#jumbotron {
  width: 100%;
  height: 100vh;
  padding-bottom: 100px;
  position: relative;
  background-image: url("@/assets/components/jumbotron/bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;

  /* Логотип */
  .logo {
    position: absolute;
    top: 20px;
    left: 30px;
  }

  section {
    height: 100%;
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr;

    /* Слоган */
    .slogan {
      padding-bottom: 60px;
      display: flex;
      align-items: flex-end;
      font-size: 70px;
      font-weight: 900;
      line-height: 1.1;
      color: white;

      span {
        display: inline-block;
        border-bottom: 4px solid red;
        margin-bottom: 10px;
      }
    }

    /* Меню */
    .menu {
      width: 100%;
      display: grid;
      grid-template-rows: 1fr;
      grid-template-columns: repeat(5, 1fr);
      grid-gap: 20px;

      ul {
        margin: 0;
        padding: 0;

        li {
          padding: 8px 5px 10px;
          border-top:2px solid transparent;
          border-bottom:2px solid #20A1E1;
          list-style-type: none;
          font-weight: 500;
          font-size: 17px;
          color: white;
          cursor: pointer;
          transition: all 0.4s ease;

          &.header {
            color: #20A1E1;
          }

          &:hover {
            padding: 8px 5px 10px 12px;
            background-color: #E21D24;
            border-top-color: #E21D24;
            border-bottom-color: #E21D24;
            box-shadow: 0 5px 10px 2px rgba(34, 60, 80, 0.3);
            color: white;
            transform: scale(110%);
          }
        }

        &:last-child {
          border-bottom-color: transparent;
        }
      }
    }
  }

  .arrow {
    width: 38px;
    height: 100px;
    margin: 40px auto 10px auto;
    position: absolute;
    bottom: 70px;
    left: 50%;

    span {
      display: block;
      width: 30px;
      height: 30px;
      border-bottom: 5px solid #337AB7;
      border-right: 5px solid #337AB7;
      transform: rotate(45deg);
      margin: -16px 0;
      animation: arrow 2s infinite;

      &:nth-child(2) {
        animation-delay: -0.2s;
      }

      &:nth-child(3){
        animation-delay: -0.4s;
      }
    }
  }

  @keyframes arrow {
    0%{
      opacity: 0;
      transform: translate(0,-20px) rotate(45deg);
    }
    50%{
      opacity: 1;
    }
    100%{
      opacity: 0;
      transform: translate(0,20px) rotate(45deg);
    }
  }
}
</style>
