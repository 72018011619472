<template>
  <div id="design-services-logos-service">
    <p>Логотип автосериса АВТОМОЁ, - это тот же логотип группы компаний, но без дескриптора и в паре с КРУПНОЙ надписью «АВТОСЕРВИС» или «ОФИЦИАЛЬНЫЙ АВТОСЕРВИС».</p>

    <p class="header">Вот возможные сочетания этих элементов:</p>

    <div class="colors-variant">
      <div class="type">
        Чёрно-белый
      </div>
      <img src="@/assets/components/design/logos/service/service-black-and-white.jpg" alt="Чёрно-белый АВТОСЕРВИС"/>
      <a href="/zips/58_Service_black.zip">
        <img src="@/assets/components/design/download.png" alt="Скачать"/>
      </a>
      <img src="@/assets/components/design/logos/service/official-service-black-and-white.jpg" alt="Чёрно-белый ОФИЦИАЛЬНЫЙ АВТОСЕРВИС"/>
      <a href="/zips/62_Official_Service_black.zip">
        <img src="@/assets/components/design/download.png" alt="Скачать"/>
      </a>

      <div class="type">
        Полноцветный
      </div>
      <img src="@/assets/components/design/logos/service/service-full-color.jpg" alt="Полноцветный АВТОСЕРВИС"/>
      <a href="/zips/59_Service_color.zip">
        <img src="@/assets/components/design/download.png" alt="Скачать"/>
      </a>
      <img src="@/assets/components/design/logos/service/official-service-full-color.jpg" alt="Полноцветный ОФИЦИАЛЬНЫЙ АВТОСЕРВИС"/>
      <a href="/zips/63_Official_Service_color.zip">
        <img src="@/assets/components/design/download.png" alt="Скачать"/>
      </a>

      <div class="type">
        Полноцветный<br />
        инверсия
      </div>
      <img src="@/assets/components/design/logos/service/service-full-color-inversion.jpg" alt="Полноцветный АВТОСЕРВИС инверсия"/>
      <a href="/zips/60_Service_color_invers.zip">
        <img src="@/assets/components/design/download.png" alt="Скачать"/>
      </a>
      <img src="@/assets/components/design/logos/service/official-service-full-color-inversion.jpg" alt="Полноцветный ОФИЦИАЛЬНЫЙ АВТОСЕРВИС инверсия"/>
      <a href="/zips/64_Official_Service_color_invers.zip">
        <img src="@/assets/components/design/download.png" alt="Скачать"/>
      </a>

      <div class="type">
        Чёрно-белый<br />
        инверсия
      </div>
      <img src="@/assets/components/design/logos/service/service-black-and-white-inversion.jpg" alt="Чёрно-белый АВТОСЕРВИС инверсия"/>
      <a href="/zips/61_Service_black_invers.zip">
        <img src="@/assets/components/design/download.png" alt="Скачать"/>
      </a>
      <img src="@/assets/components/design/logos/service/official-service-black-and-white-inversion.jpg" alt="Чёрно-белый ОФИЦИАЛЬНЫЙ АВТОСЕРВИС инверсия"/>
      <a href="/zips/65_Official_Service_black_invers.zip">
        <img src="@/assets/components/design/download.png" alt="Скачать"/>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "DesignServicesLogosService"
}
</script>

<style lang="scss">
#design-services-logos-service {
  width: 100%;

  &>p {
    width: 100%;
    padding-right: 25%;

    &.header {
      font-weight: 700;
      font-size: 20px;
      padding-right: 30%;
    }
  }

  .base-variant {
    width: 100%;
    margin-top: 35px;
    margin-bottom: 65px;
    padding-right: 25%;
    display: grid;
    grid-auto-flow: column;
    grid-template-rows: max-content max-content max-content;
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 35px;
    grid-column-gap: 55px;

    .type {
      font-weight: 300;
      font-size: 35px;
      line-height: 1.0;
    }

    &>img {
      max-width: 100%;
      margin: auto;
    }

    a {
      margin-left: auto;
      width: max-content;
      text-decoration: none;
    }
  }

  .colors-variant {
    width: 100%;
    margin-top: 55px;
    margin-bottom: 65px;
    padding-right: 25%;
    display: grid;
    grid-auto-flow: column;
    grid-template-rows: repeat(5, max-content);
    grid-template-columns: repeat(4, 1fr);
    grid-row-gap: 10px;
    grid-column-gap: 10px;

    .type {
      margin-bottom: 25px;
      font-weight: 300;
      font-size: 20px;
      line-height: 1.0;
      text-align: center;
    }

    &>img {
      max-width: 100%;
      margin: auto;
    }

    a {
      margin-left: auto;
      width: max-content;
      text-decoration: none;
    }
  }
}
</style>
