<template>
  <div id="brand-mission">
    <h3>Миссия</h3>
    <p>Обеспечение безопасной ежедневной эксплуатации автомобиля, оказывая профессиональный сервис по обслуживанию и ремонту, своевременно поставляя всем нашим клиентам высококачественные и доступные автозапчасти, проявляя заботу и внимание к каждому.</p>
    <div class="slogan">
      <span>РАБОТАЕМ С ЛЮДЬМИ</span>,<br />
      <span>А НЕ С ЗАПЧАСТЯМИ</span>.
    </div>
  </div>
</template>

<script>
export default {
  name: "BrandMission"
}
</script>

<style lang="scss">
#brand-mission {
  width: 100%;

  h3 {
    margin-bottom: 55px;
  }

  p {
    width: 100%;
    padding-right: 40%;
  }

  .slogan {
    margin-top: 45px;
    margin-bottom: 65px;
    font-weight: 300;
    font-size: 55px;

    span {
      border-bottom: 2px solid red;
    }
  }
}
</style>
