<template>
  <div id="interaction-partnership">
    <h3>Партнерство</h3>
    <p>Партнерство не должно идти вразрез с принципами бренда. Выбираем в партнеры только компании и организации, которые близки нам по принципам.</p>
    <div class="partnership-grid">
      <div>
        <div class="header error">Нет</div>
        <div class="description">Алкогольные бренды, табачные бренды в том числе кальяны и вейпы, клубы единоборств, ночные клубы, сомнительные компании, имеющие плохую репутацию, прямые конкуренты.</div>
      </div>
      <div>
        <div class="header success">Да</div>
        <div class="description">ГИБДД и другие организации, отвечающие за безопасность дорожного движения, школы, автозаправочные станции,  автосервисы и шиномонтаж (если не являются прямыми конкурентами). Кафе, продуктовые магазины, магазины строительных материалов, спортивных товаров, товаров для охоты и рыбалки.</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "InteractionPartnership"
}
</script>

<style lang="scss">
#interaction-partnership {
  width: 100%;

  h3 {
    margin-top: 80px;
    margin-bottom: 60px;
  }

  p {
    width: 100%;
    padding-right: 30%;
  }

  .partnership-grid {
    width: 100%;
    margin-top: 35px;
    padding-right: 50%;
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;

    .header {
      margin-bottom: 15px;
      font-weight: 500;
      font-size: 30px;
    }

    .error {
      color: red;
    }

    .success {
      color: #0C415A;
    }
  }
}
</style>
