<template>
  <div class="download-box">
    <img class="imgdwnld" src="@/assets/components/design/download.png" alt="Скачать"/>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "DownloadPopUp"
}
</script>

<style lang="scss">
.download-box {
  min-width: max-content;
  position: absolute;
  top: -15px;
  left: -15px;
  padding: 15px 15px 15px 15px;
  background-color: white;
  border-radius: 15px;
  box-shadow: 0 5px 10px -2px rgba(34, 60, 80, 0.2);
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s, visibility 0s linear 0.3s;

  .imgdwnld {
    display: block;
    margin-bottom: 5px;
  }

  a {
    text-decoration: none;
    color: #20A1E1;

    &:hover {
      color: red;
    }
  }
}

.download:hover {
  .download-box {
    opacity: 1;
    visibility: visible;
    transition-delay: 0s;
  }
}
</style>