<template>
  <div id="corporate-style-stores">
    <h3>Оформление магазинов</h3>

    <p>Все магазины группы компаний АВТОМОЁ приводятся к единому стилю оформления. Для этого производится покраска стен, в  регламентированные цвета, При необходимости, - на стены может быть нанесён паттерн. Так же размещаются имиджевые, рекламные и информационные материалы и оборудование. Закупается мебель.</p>

    <p class="header">Покраска стен</p>

    <div class="wall-painting">
      <div class="ral-5005">
        <span>RAL-5005</span><br />
        тёмно-синий
      </div>
      <div class="ral-180-3">
        <span>RAL-180-3</span><br />
        серо-голубой
      </div>
      <div class="ral-5012">
        <span>RAL-5012</span><br />
        паттерн
      </div>
    </div>

    <p class="header">Имиджевые и информационные материалы</p>

    <div class="information-materials">
      <div class="name">
        Информационный<br />
        стенд
      </div>
      <img src="@/assets/components/corporate-style/stores/information-materials/information-board.png" alt="Информационный стенд"/>
      <div class="description">
        Пластик 5мм. Плёнка полноцветная печать. Карманы из капола или оргстекла. Количество карманов: 4, 6 или 8;
      </div>
      <div class="download">
        <img src="@/assets/components/design/download.png" alt="Скачать"/>
        <DownloadPopUp>
          Материалы готовятся.
        </DownloadPopUp>
      </div>

      <div class="name">
        Интерьерная<br />
        вывеска
      </div>
      <img src="@/assets/components/corporate-style/stores/information-materials/interior-signboard.png" alt="Интерьерная вывеска"/>
      <div class="description">
        Логотип компании, или направления. Фрезеровка пластика 5мм. Плёнка полноцветная печать. Монтаж на дистанционные держатели. Размер зависит от площади размещения
      </div>
      <div class="download">
        <img src="@/assets/components/design/download.png" alt="Скачать"/>
        <DownloadPopUp>
          Материалы готовятся.
        </DownloadPopUp>
      </div>

      <div class="name">
        Экран перед<br />
        монитором
      </div>
      <img src="@/assets/components/corporate-style/stores/information-materials/screen-in-front-of-monitor.png" alt="Экран перед монитором"/>
      <div class="description">
        Оргстекло 3 мм логотип - белая плёнка, полноцветная печать. Резка (выклейка снаружи) паттерн - прозрачная матовая плёнка, полноцветная печать (выклейка изнутри). Карман A3, капол.
      </div>
      <div class="download">
        <img src="@/assets/components/design/download.png" alt="Скачать"/>
        <DownloadPopUp>
          Материалы готовятся.
        </DownloadPopUp>
      </div>
    </div>

    <p class="header">Магазины группы компаний АВТОМОЁ можно разделить на 2 категории:</p>

    <h4 class="blue">ПУНКТ ВЫДАЧИ</h4>

    <div class="point-values">
      <div class="name">площадь:</div>
      <div class="value">40-70 м<sup>2</sup></div>

      <div class="name">склад:</div>
      <div class="value">800 - 1200 т.р.</div>

      <div class="name">рабочих мест:</div>
      <div class="value">1 и более</div>
    </div>

    <div class="point-images">
      <div class="image-box">
        <img src="@/assets/components/corporate-style/stores/extraction-point/extraction-point-01.png" alt="Пункт выдачи (схема 1)">
      </div>
      <div class="image-box">
        <img src="@/assets/components/corporate-style/stores/extraction-point/extraction-point-02.png" alt="Пункт выдачи (схема 2)">
      </div>
    </div>

    <p>Пункт выдачи открывается в торговых центрах, но может открываться и как отдельно стоящее здание. Суть пункта выдачи - дать возможность клиенту заказать нужный товар с доставкой до пункта выдачи без необходимости ехать в магазин.</p>
    <p>Ассортимент дополняется большим количеством аксессуаров, автохимии, так как этот товар пользуется спросом в магазинах такого формата.</p>

    <div class="point-requirements">
      <div class="name">АССОРТИМЕНТ:</div>
      <ul>
        <li>Автозапчасти</li>
        <li>Товары группы А</li>
        <li>Масла</li>
        <li>Аксессуары</li>
      </ul>

      <div class="name">ОФОРМЛЕНИЕ:</div>
      <ul>
        <li>Вывеска, световые буквы</li>
        <li>Режим работы</li>
        <li>Окна не заклеены</li>
        <li>Лайтбокс или аппликация на окна</li>
        <li>Доска информации</li>
        <li>Вывеска "пункт выдачи"</li>
      </ul>

      <div class="name">ВЫКЛАДКА:</div>
      <ul>
        <li>Витрины не менее 2</li>
        <li>Низкие витрины 2-4</li>
        <li>Экономпанели 3</li>
      </ul>
    </div>

    <h4 class="blue">МАГАЗИН АВТОЗАПЧАСТЕЙ</h4>

    <div class="point-values">
      <div class="name">площадь:</div>
      <div class="value">70-120 м<sup>2</sup></div>

      <div class="name">склад:</div>
      <div class="value">от 3 млн.р.</div>

      <div class="name">рабочих мест:</div>
      <div class="value">не менее 2х</div>
    </div>

    <div class="point-images">
      <div class="image-box">
        <img src="@/assets/components/corporate-style/stores/parts-store/parts-store-01.png" alt="Магазин автозапчастей (схема 1)">
      </div>
      <div class="image-box">
        <img src="@/assets/components/corporate-style/stores/parts-store/parts-store-02.png" alt="Магазин автозапчастей (схема 2)">
      </div>
    </div>

    <p>Магазин автозапчастей средний формат между гипермаркетом и пунктом выдачи. Он работает в тех же высоких стандартах, что и гипермаркет, но меньше по площади, количеству рабочих мест. на складе обязательно присутствует большинство ходовых позиций.</p>

    <div class="point-requirements">
      <div class="name">АССОРТИМЕНТ:</div>
      <ul>
        <li>представлен весь</li>
        <li>ассортимент запчасти</li>
        <li>масла, тех жидкости</li>
        <li>Аксессуары</li>
      </ul>

      <div class="name">ОФОРМЛЕНИЕ:</div>
      <ul>
        <li>Вывеска, световые буквы</li>
        <li>Режим работы</li>
        <li>Окна не заклеены</li>
        <li>Лайтбокс или аппликация на окна</li>
        <li>Доска информации</li>
        <li>Интерьерные вывески</li>
        <li>Мониторы для демонстрации видеороликов на рабочих столах менеджеров</li>
      </ul>

      <div class="name">ВЫКЛАДКА:</div>
      <ul>
        <li>стенд по лампам</li>
        <li>Стенд с клипсами</li>
        <li>Открытая выкладка</li>
        <li>Не менее 4 витрин</li>
        <li>Не менее 3 экономпанелей</li>
      </ul>
    </div>

    <p>ОБЯЗАТЕЛЬНА КЛИЕНТСКАЯ ЗОНА. КОФЕМАШИНА, КУЛЕР,  ДИВАН.</p>

<!--    <h4 class="blue">ГИПЕРМАРКЕТ</h4>-->

<!--    <div class="point-values">-->
<!--      <div class="name">площадь:</div>-->
<!--      <div class="value">-->
<!--        120 м<sup>2</sup> и ><br />-->
<!--        <span>с учётом склада</span>-->
<!--      </div>-->

<!--      <div class="name">склад:</div>-->
<!--      <div class="value">6 млн.р.</div>-->

<!--      <div class="name">рабочих мест:</div>-->
<!--      <div class="value">не менее 5и</div>-->
<!--    </div>-->

<!--    <div class="hypermarket-image-box">-->
<!--      <img src="@/assets/components/corporate-style/stores/hypermarket/hypermarket.png" alt="Гипермаркет (схема 1)">-->
<!--    </div>-->

<!--    <p>Гипермаркет автозапчастей должен отражать внешним видом и наполнением свое название. Наличие автозапчастей и автотоваров отражает потребности автовладельцев конкретного города. Обязательно ассортимент дополнен аксессуарами, автохимией, чтобы закрыть большую часть потребностей автовладельцев. Отличается от остальных форматов площадью, наличием и оформлением. Слоган гипермаркет автозапчастей всегда в наличии.</p>-->

<!--    <div class="point-requirements">-->
<!--      <div class="name">АССОРТИМЕНТ:</div>-->
<!--      <ul>-->
<!--        <li>представлен весь ассортимент запчастей</li>-->
<!--      </ul>-->

<!--      <div class="name">ОФОРМЛЕНИЕ:</div>-->
<!--      <ul>-->
<!--        <li>Вывеска, световые буквы</li>-->
<!--        <li>Режим работы</li>-->
<!--        <li>Окна не заклеены</li>-->
<!--        <li>Лайтбокс или аппликация на окна</li>-->
<!--        <li>Доска информации</li>-->
<!--        <li>Интерьерные вывески</li>-->
<!--        <li>Мониторы для демонстрации видеороликов на рабочих столах менеджеров</li>-->
<!--      </ul>-->

<!--      <div class="name">ВЫКЛАДКА:</div>-->
<!--      <ul>-->
<!--        <li>Стенд с клипсами</li>-->
<!--        <li>Открытая выкладка</li>-->
<!--        <li>Не менее 2 витрин</li>-->
<!--        <li>Не менее 2 экономпанелей</li>-->
<!--      </ul>-->
<!--    </div>-->

<!--    <p>ОБЯЗАТЕЛЬНА КЛИЕНТСКАЯ ЗОНА. КОФЕМАШИНА, КУЛЕР,  ДИВАН.</p>-->

  </div>
</template>

<script>
import DownloadPopUp from "@/components/common/DownloadPopUp"

export default {
  name: "CorporateStyleStores",
  components: {
    DownloadPopUp
  },
}
</script>

<style lang="scss">
#corporate-style-stores {
  width: 100%;

  h3 {}

  h4 {
    margin-top: 65px;
    margin-bottom: 35px;

    &.blue {
      color: #05547D;
    }
  }

  &>p {
    width: 100%;
    padding-right: 25%;

    &.header {
      margin-top: 65px;
      font-weight: 700;
      font-size: 20px;
      padding-right: 30%;
    }
  }

  .wall-painting {
    width: 100%;
    padding-right: 25%;
    display: grid;
    grid-auto-flow: row;
    grid-template-rows: max-content;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 35px;

    div {
      padding: 20px 30px;

      span {
        font-weight: 700;
      }
    }

    .ral-5005 {
      background-color: #004388;
      color: white;
    }

    .ral-180-3 {
      background-color: #C8DBEA;
      color: #464646;
    }

    .ral-5012 {
      background-color: #4488C9;
      color: white;
    }
  }

  .information-materials {
    width: 100%;
    margin-top: 35px;
    margin-bottom: 65px;
    padding-right: 25%;
    display: grid;
    grid-auto-flow: column;
    grid-template-rows: repeat(4, max-content);
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: 15px;
    grid-column-gap: 35px;

    .name {
      font-weight: 300;
      font-size: 25px;
      line-height: 1.0;
      color: #054667;

      span {
        font-size: 12px;
        font-weight: 100;
      }
    }

    &>img {
      max-width: 100%;
      margin: auto;
    }

    .download {
      position: relative;
      margin-left: auto;
      width: max-content;
      text-decoration: none;
    }
  }

  .point-values {
    width: 100%;
    padding-right: 25%;
    display: grid;
    grid-auto-flow: column;
    grid-template-rows: repeat(2, max-content);
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: 0;
    grid-column-gap: 35px;
    color: #727270;

    .name {
      font-size: 25px;
      font-weight: 300;
      line-height: 1;
    }

    .value {
      font-size: 38px;
      font-weight: 700;
      line-height: 1;

      sup {
        font-size: 20px;
      }

      span {
        display: block;
        padding-left: 8px;
        font-size: 15px;
        font-weight: 300;
        line-height: 1;
      }
    }
  }

  .point-images {
    width: 100%;
    margin-top: 35px;
    padding-right: 25%;
    display: grid;
    grid-auto-flow: column;
    grid-template-rows: repeat(1, max-content);
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 35px;

    .image-box {
      width: 100%;

      img {
        max-width: 100%;
      }
    }
  }

  .hypermarket-image-box {
    width: 100%;
    margin-top: 35px;
    padding-right: 25%;

    img {
      max-width: 100%;
    }
  }

  .point-requirements {
    width: 100%;
    margin-top: 35px;
    padding-right: 25%;
    display: grid;
    grid-auto-flow: column;
    grid-template-rows: repeat(2, max-content);
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 55px;

    .name {
      font-weight: 700;
    }

    ul {
      padding-left: 20px;

      li {
        list-style-type: square;
      }
    }
  }
}
</style>
