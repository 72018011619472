<template>
  <div id="design-brand-character-gallery">
    <div>
      <img src="@/assets/components/design/brand-character/gallery/kolyan-gallery-01.jpg" alt="Изображение галереи Колян (01)"/>
      <a href="#">
        <img src="@/assets/components/design/download.png" alt="Скачать"/>
      </a>
    </div>
    <div>
      <img src="@/assets/components/design/brand-character/gallery/kolyan-gallery-02.jpg" alt="Изображение галереи Колян (02)"/>
      <a href="#">
        <img src="@/assets/components/design/download.png" alt="Скачать"/>
      </a>
    </div>
    <div>
      <img src="@/assets/components/design/brand-character/gallery/kolyan-gallery-03.jpg" alt="Изображение галереи Колян (03)"/>
      <a href="#">
        <img src="@/assets/components/design/download.png" alt="Скачать"/>
      </a>
    </div>
    <div>
      <img src="@/assets/components/design/brand-character/gallery/kolyan-gallery-04.jpg" alt="Изображение галереи Колян (04)"/>
      <a href="#">
        <img src="@/assets/components/design/download.png" alt="Скачать"/>
      </a>
    </div>
    <div>
      <img src="@/assets/components/design/brand-character/gallery/kolyan-gallery-05.jpg" alt="Изображение галереи Колян (05)"/>
      <a href="#">
        <img src="@/assets/components/design/download.png" alt="Скачать"/>
      </a>
    </div>
    <div>
      <img src="@/assets/components/design/brand-character/gallery/kolyan-gallery-06.jpg" alt="Изображение галереи Колян (06)"/>
      <a href="#">
        <img src="@/assets/components/design/download.png" alt="Скачать"/>
      </a>
    </div>
    <div>
      <img src="@/assets/components/design/brand-character/gallery/kolyan-gallery-08.jpg" alt="Изображение галереи Колян (08)"/>
      <a href="#">
        <img src="@/assets/components/design/download.png" alt="Скачать"/>
      </a>
    </div>
    <div>
      <img src="@/assets/components/design/brand-character/gallery/kolyan-gallery-07.jpg" alt="Изображение галереи Колян (07)"/>
      <a href="#">
        <img src="@/assets/components/design/download.png" alt="Скачать"/>
      </a>
    </div>
    <div>
      <img src="@/assets/components/design/brand-character/gallery/kolyan-gallery-09.jpg" alt="Изображение галереи Колян (09)"/>
      <a href="#">
        <img src="@/assets/components/design/download.png" alt="Скачать"/>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "DesignBrandCharacterGallery"
}
</script>

<style lang="scss">
#design-brand-character-gallery {
  width: 100%;
  margin-top: 35px;
  display: grid;
  grid-template-rows: repeat(3, max-content);
  grid-template-columns: repeat(3, 1fr);
  grid-row-gap: 55px;
  grid-column-gap: 55px;

  div {
    display: flex;
    flex-direction: column;

    &>img {
      max-width: 100%;
      margin-bottom: 15px;
    }

    a {
      margin-left: auto;
      width: max-content;
      text-decoration: none;
    }
  }
}
</style>
