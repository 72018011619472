<template>
  <div id="interaction-promotions">
    <h3>Акции</h3>
    <p>Типы акций:</p>
    <div class="promotions-grid">
      <div>
        <div class="type">
          ПРИВЛЕЧЕНИЕ<br />
          ПОКУПАТЕЛЕЙ
        </div>
        <div>Эти акции помогают привлечь внешний трафик в магазин и направлены напрямую на увеличение количества покупателей. Такие акции необходимо массово продвигать, говорить о них вне магазина.</div>
      </div>
      <div>
        <div class="type">
          УВЕЛИЧЕНИЕ<br />
          СРЕДНЕГО ЧЕКА
        </div>
        <div>Эти акции помогают продать больше и дороже без привлечения дополнительного трафика в магазин. Обычно информацию о них мы размещаем внутри магазина, например, на плакатах перед мониторами.</div>
      </div>
      <div>
        <div class="type">
          ПОВТОРНЫЕ ПРОДАЖИ<br />
          И УДЕРЖАНИЕ ПОКУПАТЕЛЕЙ
        </div>
        <div>Эти задачи решаются установленной программой лояльности и ее инструментами, а также различными механиками, которые позволяют побудить покупателя вернуться в магазин.</div>
      </div>
    </div>
    <p>Из каналов продвижения мы можем использовать наши группы в социальных сетях, смс и имэйл рассылки, рассылки в мессенджерах, обзвоны, флаеры в магазинах с особыми условиями на следующую покупку.</p>
  </div>
</template>

<script>
export default {
  name: "InteractionPromotions"
}
</script>

<style lang="scss">
#interaction-promotions {
  width: 100%;

  h3 {
    margin-top: 80px;
    margin-bottom: 60px;
  }

  .promotions-grid {
    width: 100%;
    margin-top: 35px;
    margin-bottom: 55px;
    padding-right: 25%;
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 30px;

    .type {
      margin-bottom: 15px;
      font-weight: 700;
    }
  }

  p {
    width: 100%;
    padding-right: 40%;
  }
}
</style>
