<template>
  <div id="breadcrumbs">
    <img class="logo" src="@/assets/components/breadcrumbs/logo-menu.png">
    <nav class="breadcrumbs">
      <div href="#" class="item">Брендбук</div>
      <div href="#" class="item">{{ $store.state.breadcrumbs.component }}</div>
      <div href="#" class="item">{{ $store.state.breadcrumbs.element }}</div>
    </nav>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Breadcrumbs",
}
</script>

<style lang="scss">
#breadcrumbs {
  width: 100%;
  padding: 20px 25px;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: max-content 1fr;
  grid-gap: 40px;
  background-color: white;
  box-shadow: 0px 28px 41px 2px rgba(34, 60, 80, 0.2);
  position: sticky;
  top: 0;
  bottom: 0;
  z-index: 100;

  .breadcrumbs {
    width: fit-content;
    border: 1px solid #20A1E1;
    border-radius: 5px;
    display: inline-flex;
    overflow: hidden;

    .item {
      padding: 8px 30px 10px;
      position: relative;
      background: #fff;
      outline: none;
      text-decoration: none;
      color: #20A1E1;
      transition: all 0.3s ease;

      &:hover:after,
      &:hover {
        background: #20A1E1;
        color: white;
      }

      &:focus:after,
      &:focus {
        background: #20A1E1;
        color: #fff;
      }

      &:after,
      &:before {
        width: 1em;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 100%;
        background: white;
        clip-path: polygon(50% 50%, -50% -50%, 0 100%);
        content: "";
        transition: background 0.2s linear;
        z-index: 1;
      }

      &:before {
        background: #20A1E1;
        margin-left: 1px;
      }

      &:last-child {
        border-right: none;
      }
    }
  }
}
</style>
