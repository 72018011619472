<template>
  <div id="brand-character">
    <h3>Характер бренда АВТОМОЁ</h3>
    <p><span>Позиционирование</span>: помогающая компания.</p>
    <p><span>Главные ценности бренда</span>: честность, открытость, безопасность, гарантии, поддержка, здоровье, семья, партнерство. Несопоставимо с брендом: алкоголь, курение, эротика, насилие, ложь, антироссийские высказывания, унижение, мат, долги, задолженности, просрочки платежей.</p>
    <p><span>Слова, которые ассоциируются с брендом</span>: безопасность, надежность, дружба, помощь, поддержка, гарантия, круглосуточно, удобно, наличие, производство, всегда, запчасти, сервис, ремонт, проверено, сертификаты соответствия, интернет-магазин, удобно, быстро, качество, постоянно, федеральная сеть, эксперт, профессионально, решение.</p>
  </div>
</template>

<script>
export default {
  name: "BrandCharacter"
}
</script>

<style lang="scss">
#brand-character {
  width: 100%;
  margin-top: 95px;

  h3 {}

  p {

    span {
      font-weight: 500;
    }
  }
}
</style>
