<template>
  <div id="audience-segments">
    <h3>Сегменты аудитории</h3>

    <div class="segments-grid">
      <div>
        <img src="@/assets/components/audience/car-owners.png" alt="Физические лица владельцы">
        <p class="header">
          ФИЗИЧЕСКИЕ ЛИЦА ВЛАДЕЛЬЦЫ<br />
          АВТОМОБИЛЕЙ ЛЮБЫХ МАРОК
        </p>
        <p>Для этой целевой аудитории характерно делать упор на безопасность и надежность продукта, который они хотят приобрести. Им важно иметь выбор в различных ценовых сегментах, а также гарантию качества. Важный фактор – возможность легкого возврата.</p>
      </div>
      <div>
        <img src="@/assets/components/audience/car-repair.png" alt="Авторемонтные мастерские">
        <p class="header">
          АВТОРЕМОНТНЫЕ МАСТЕРСКИЕ,<br />
          ЮРИДИЧЕСКИЕ ЛИЦА
        </p>
        <p>Этой целевой аудитории важна скорость поставки, так как машина чаще всего уже  в процессе ремонта на подъемнике. Важна цена и режим налогообложения (например, ндс).</p>
      </div>
      <div>
        <img src="@/assets/components/audience/car-fleets.png" alt="Владельцы автопарков">
        <p class="header">
          ВЛАДЕЛЬЦЫ КРУПНЫХ<br />
          АВТОПАРКОВ
        </p>
        <p>Ключевые параметры - скорость поставки запчастей и ремонта, так как простой автомобиля отражается на доходе бизнесмена. Цены и корпоративные скидки- важный момент.</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AudienceSegments"
}
</script>

<style lang="scss">
#audience-segments {
  width: 100%;

  h3 {
    margin-top: 85px;
    margin-bottom: 80px;
  }

  .segments-grid {
    width: 100%;
    padding-right: 25%;
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;

    img {
      max-width: 100%;
      margin-bottom: 35px;
      transform: scale(120%);
    }

    p {

      &.header {
        font-weight: 700;
      }
    }
  }
}
</style>
