import Vue from 'vue'
import App from './App.vue'

import Vuex from 'vuex'
Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    breadcrumbs: {
      component: 'Бренд',
      element: 'Интро'
    }
  },
  mutations: {
    setBreadcrumbs (state, payload) {
      state.breadcrumbs = payload
    }
  }
})

import Inview from 'vue-inview'
Vue.use(Inview)

import VueScrollTo from 'vue-scrollto'
Vue.use(VueScrollTo)

Vue.config.productionTip = false

Vue.directive('scroll', {
  inserted: function (el, binding) {
    let f = function (evt) {
      if (binding.value(evt, el)) {
        window.removeEventListener('scroll', f)
      }
    }
    window.addEventListener('scroll', f)
  }
})

new Vue({
  store: store,
  mounted() {
    this.$on('inview-enter', (data) => {
      this.$store.commit('setBreadcrumbs', data)
    })
  },
  render: h => h(App),
}).$mount('#app')
