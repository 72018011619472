<template>
  <div id="corporate-style">
    <section>
      <div class="header-with-menu">
        <h2>КОРПОРАТИВНЫЙ СТИЛЬ</h2>
        <div class="menu">
          <ul>
            <li v-scroll-to="{el: '#corporate-style-stores', duration: 600, easing: 'easeInOutQuad', offset: -200}">Оформление магазинов</li>
            <li v-scroll-to="{el: '#corporate-style-clothing', duration: 600, easing: 'easeInOutQuad', offset: -200}">Корпоративная одежда</li>
          </ul>
        </div>
      </div>

      <CorporateStyleStores v-inview="{component: 'Корпоративный стиль', element: 'Оформление магазинов'}"/>
      <CorporateStyleClothing v-inview="{component: 'Корпоративный стиль', element: 'Корпоративная одежда'}"/>
    </section>
  </div>
</template>

<script>
import CorporateStyleStores from "@/components/corporate-style/CorporateStyleStores"
import CorporateStyleClothing from "@/components/corporate-style/CorporateStyleClothing"

export default {
  name: "CorporateStyle",
  components: {
    CorporateStyleStores,
    CorporateStyleClothing,
  },
}
</script>

<style lang="scss">
#corporate-style {
  width: 100%;

  section {
    padding-top: 55px;
    padding-bottom: 55px;
  }
}
</style>
