<template>
  <div id="design-brand-character">
    <h3>Персонаж бренда</h3>

    <div class="brand-character-box">
      <div class="description">
        <p>Персонаж назван Колян, благодаря популярности этого имени в городе, где родилась компания – в Перми.</p>
        <p>Персонаж ведет здоровый образ жизни, участвует в соревнованиях, не ассоциируется с вредными привычками. Любит находится в компании дружных семей на городских мероприятиях, поддерживает спортсменов на соревнованиях, выступает на акциях за безопасность дорожного движения, в экологических акциях. Может рассказать, как безопасно эксплуатировать свой автомобиль, при этом не затрачивая больших ресурсов.</p>
        <p>Он активен, проявляет инициативу, любит Россию. Он всегда готов прийти на помощь в любой проблеме с автомобилем.</p>
      </div>
      <div class="image">
        <img src="@/assets/components/design/brand-character/kolyan.jpg" alt="Персонаж Колян">
      </div>
    </div>

    <img
        class="cursorPointer"
        src="@/assets/components/design/brand-character/open-gallery.png"
        alt="Показать галерею"
        v-if="isGalleryHidden"
        @click="switchGalleryHide"
    >
    <img
        class="cursorPointer"
        src="@/assets/components/design/brand-character/close-gallery.png"
        alt="Показать галерею"
        v-if="!isGalleryHidden"
        @click="switchGalleryHide"
    >
    <DesignBrandCharacterGallery v-if="!isGalleryHidden"/>
  </div>
</template>

<script>
import DesignBrandCharacterGallery from "@/components/design/design-brand-character/DesignBrandCharacterGallery"

export default {
  name: "DesignBrandCharacter",
  components: {
    DesignBrandCharacterGallery
  },
  data() {
    return {
      isGalleryHidden: true,
    }
  },
  methods: {
    switchGalleryHide () {
      this.isGalleryHidden = !this.isGalleryHidden
    }
  }
}
</script>

<style lang="scss">
#design-brand-character {
  width: 100%;
  margin-top: 95px;

  h3 {

  }

  .brand-character-box {
    width: 100%;
    padding-right: 10%;
    display: flex;
    flex-direction: row;

    .description {
      width: 50%;
      padding-right: 10%;
    }

    .image {
      flex-grow: 1;
      padding-right: 10%;

      img {
        max-width: 100%;
      }
    }
  }

  .cursorPointer {
    cursor: pointer;
  }
}
</style>
