<template>
  <div id="interaction-formulations">
    <h3>Формулировки</h3>
    <p>Рекламные слоганы</p>
    <div class="formulations-grid">
      <div>
        <div class="header error">Нет</div>
        <div class="example error">ЗАПЧАСТИ В НАЛИЧИИ И ПОД ЗАКАЗ</div>
        <div class="example error">БЕСПРОИГРЫШНАЯ ЛОТЕРЕЯ</div>
        <div class="example error">Лучший/самый лучший магазин запчастей</div>
      </div>
      <div>
        <div class="header success">Да</div>
        <div class="example success">ЗАПЧАСТИ ВСЕГДА В НАЛИЧИИ</div>
        <div class="example success">РОЗЫГРЫШ ПОДАРКОВ</div>
        <div class="example success">Дарим 500 рублей на покупку автозапчастей</div>
        <div class="example success">Магазины во всех районах города</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "InteractionFormulations"
}
</script>

<style lang="scss">
#interaction-formulations {
  width: 100%;

  h3 {
    margin-top: 80px;
    margin-bottom: 60px;
  }

  .formulations-grid {
    width: 100%;
    margin-top: 35px;
    padding-right: 30%;
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;

    .header {
      margin-bottom: 20px;
      padding-left: 10px;
      font-weight: 500;
      font-size: 30px;

      &.error {
        color: #E21E25;
      }

      &.success {
        color: #0C415A;
      }
    }

    .example {
      margin-bottom: 25px;
      padding-left: 10px;
      line-height: 1;

      &.error {
        border-left: 2px solid #E21E25;
      }

      &.success {
        border-left: 2px solid #0C415A;
      }
    }
  }
}
</style>
