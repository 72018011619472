<template>
  <div id="brand">
    <section>
      <div class="header-with-menu">
        <h2>Бренд</h2>
        <div class="menu">
          <ul>
            <li v-scroll-to="{el: '#brand-mission', duration: 600, easing: 'easeInOutQuad', offset: -200}">Миссия</li>
            <li v-scroll-to="{el: '#values', duration: 600, easing: 'easeInOutQuad', offset: -200}">Ценности</li>
          </ul>
          <ul>
            <li v-scroll-to="{el: '#brand-character', duration: 600, easing: 'easeInOutQuad', offset: -200}">Характер бренда</li>
          </ul>
        </div>
      </div>

      <BrandIntro v-inview="{component: 'Бренд', element: 'Интро'}"/>
      <BrandMission v-inview="{component: 'Бренд', element: 'Миссия'}"/>
      <BrandValues v-inview="{component: 'Бренд', element: 'Ценности'}"/>
      <BrandCharacter v-inview="{component: 'Бренд', element: 'Характер бренда АВТОМОЁ'}"/>
    </section>
  </div>
</template>

<script>
import BrandIntro from "@/components/brand/BrandIntro"
import BrandMission from "@/components/brand/BrandMission"
import BrandValues from "@/components/brand/BrandValues"
import BrandCharacter from "@/components/brand/BrandCharacter"

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Brand",
  components: {
    BrandIntro,
    BrandMission,
    BrandValues,
    BrandCharacter,
  },
  methods: {

  },
}
</script>

<style lang="scss">
#brand {
  width: 100%;

  section {
    padding-top: 55px;
    padding-bottom: 55px;
  }
}
</style>
