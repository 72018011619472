<template>
  <div id="design-main-logos-main">
    <div class="base-variant">
      <div class="description">Основная версия нашего логотипа — горизонтальная, однострочная. Подходит для любых носителей и ситуаций.</div>
      <img src="@/assets/components/design/logos/main/main-company-group.png" alt="Основная версия"/>
      <a href="/zips/1_main-company-group.zip">
        <img src="@/assets/components/design/download.png" alt="Скачать"/>
      </a>

      <div class="description">Так же, существует версия с дескриптором «ЗАПЧАСТИ И СЕРВИС». Подходит для случаев, когда  необходимо обозначить причастность не только к сфере автотоваров но и услуг по ремонту автомобиля.</div>
      <img src="@/assets/components/design/logos/main/main-parts-and-service.png" alt="версия с дескриптором ЗАПЧАСТИ И СЕРВИС"/>
      <a href="/zips/2_main-parts-and-service.zip">
        <img src="@/assets/components/design/download.png" alt="Скачать"/>
      </a>
    </div>

    <p>Дополнительно, существует несколько версий логотипов, использование которых допустимо в редких случаях:</p>

    <div class="additionally-variant">
      <div class="type">
        Вертикальный<br />
        логотип
      </div>
      <img src="@/assets/components/design/logos/main/main-vertical.png" alt="Вертикальный логотип"/>
      <a href="/zips/3_main-vertical.zip">
        <img src="@/assets/components/design/download.png" alt="Скачать"/>
      </a>
      <div class="description">Используется в нестандартных ситуациях, на узких вертикальных или квадратных поверхностях или носителях. Например флаги.</div>

      <div class="type">
        Текстовый<br />
        логотип
      </div>
      <img src="@/assets/components/design/logos/main/main-text.png" alt="Текстовый логотип"/>
      <a href="/zips/4_main-text.zip">
        <img src="@/assets/components/design/download.png" alt="Скачать"/>
      </a>
      <div class="description">Подходит для случаев когда технология брендирования не позволяет отобразить все мелкие детали знака.</div>

      <div class="type">
        Знак
      </div>
      <img src="@/assets/components/design/logos/main/main-sign.png" alt="Знак"/>
      <a href="/zips/5_main-sign.zip">
        <img src="@/assets/components/design/download.png" alt="Скачать"/>
      </a>
      <div class="description">Для обозначения на картах в навигаторах, или иконках в соцсетях.</div>
    </div>

    <p class="header">У всех вышеперечисленных логотипов существует 4 цветовые вариации:</p>

    <div class="colors-variant">
      <div class="type">
        Чёрно-белый
      </div>
      <img src="@/assets/components/design/logos/main/main-black-and-white.jpg" alt="Чёрно-белый"/>
      <a href="/zips/6_main-black-and-white.zip">
        <img src="@/assets/components/design/download.png" alt="Скачать"/>
      </a>

      <div class="type">
        Полноцветный
      </div>
      <img src="@/assets/components/design/logos/main/main-full-color.jpg" alt="Полноцветный"/>
      <a href="/zips/7_main-full-color.zip">
        <img src="@/assets/components/design/download.png" alt="Скачать"/>
      </a>

      <div class="type">
        Полноцветный<br />
        инверсия
      </div>
      <img src="@/assets/components/design/logos/main/main-full-color-inversion.jpg" alt="Полноцветный инверсия"/>
      <a href="/zips/8_main-full-color-inversion.zip">
        <img src="@/assets/components/design/download.png" alt="Скачать"/>
      </a>

      <div class="type">
        Чёрно-белый<br />
        инверсия
      </div>
      <img src="@/assets/components/design/logos/main/main-black-and-white-inversion.jpg" alt="Чёрно-белый инверсия"/>
      <a href="/zips/9_main-black-and-white-inversion.zip">
        <img src="@/assets/components/design/download.png" alt="Скачать"/>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "DesignMainLogosMain"
}
</script>

<style lang="scss">
#design-main-logos-main {
  width: 100%;

  .base-variant {
    width: 100%;
    margin-bottom: 65px;
    padding-right: 25%;
    display: grid;
    grid-auto-flow: column;
    grid-template-rows: repeat(3, max-content);
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 35px;
    grid-column-gap: 55px;

    &>img {
      max-width: 100%;
    }

    a {
      margin-left: auto;
      width: max-content;
      text-decoration: none;
    }
  }

  &>p {
    width: 100%;
    padding-right: 45%;

    &.header {
      font-weight: 700;
      font-size: 20px;
      padding-right: 30%;
    }
  }

  .additionally-variant {
    width: 100%;
    margin-top: 55px;
    margin-bottom: 65px;
    padding-right: 25%;
    display: grid;
    grid-auto-flow: column;
    grid-template-rows: repeat(4, max-content);
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: 35px;
    grid-column-gap: 55px;

    .type {
      font-weight: 300;
      font-size: 35px;
      line-height: 1.0;
    }

    &>img {
      max-width: 100%;
      margin: auto;
    }

    a {
      margin-left: auto;
      width: max-content;
      text-decoration: none;
    }
  }

  .colors-variant {
    width: 100%;
    margin-top: 55px;
    margin-bottom: 65px;
    padding-right: 25%;
    display: grid;
    grid-auto-flow: column;
    grid-template-rows: repeat(3, max-content);
    grid-template-columns: repeat(4, 1fr);
    grid-row-gap: 10px;
    grid-column-gap: 10px;

    .type {
      margin-bottom: 25px;
      font-weight: 300;
      font-size: 20px;
      line-height: 1.0;
      text-align: center;
    }

    &>img {
      max-width: 100%;
      margin: auto;
    }

    a {
      margin-bottom: 10px;
      margin-left: auto;
      width: max-content;
      text-decoration: none;
    }
  }
}
</style>
