<template>
  <div id="design-direction-logos-auto-russian">
    <p>У каждого направления существует несколько логотипов - горизонтального и вертикального ориентирования.</p>

    <div class="base-variant">
      <div class="type">
        Вертикальный<br />
        логотип
      </div>
      <img src="@/assets/components/design/logos/auto-russian/main-vertical.png" alt="Вертикальный логотип"/>

      <div class="type">
        Горизонтальный<br />
        логотип
      </div>
      <img src="@/assets/components/design/logos/auto-russian/main-horizontal.png" alt="Горизонтальный логотип"/>
    </div>

    <p class="header">Каждый из которых возможно представить в 4 цветовых вариациях:</p>

    <div class="colors-variant">
      <div class="type">
        Чёрно-белый
      </div>
      <img src="@/assets/components/design/logos/auto-russian/vertical-black-and-white.png" alt="Чёрно-белый вертикальный"/>
      <a href="/zips/50_Russian_V_black.zip">
        <img src="@/assets/components/design/download.png" alt="Скачать"/>
      </a>
      <img src="@/assets/components/design/logos/auto-russian/horizontal-black-and-white.png" alt="Чёрно-белый горизонтальный"/>
      <a href="/zips/54_Russian_H_black.zip">
        <img src="@/assets/components/design/download.png" alt="Скачать"/>
      </a>

      <div class="type">
        Полноцветный
      </div>
      <img src="@/assets/components/design/logos/auto-russian/vertical-full-color.png" alt="Полноцветный вертикальный"/>
      <a href="/zips/51_Russian_V_color.zip">
        <img src="@/assets/components/design/download.png" alt="Скачать"/>
      </a>
      <img src="@/assets/components/design/logos/auto-russian/horizontal-full-color.png" alt="Полноцветный горизонтальный"/>
      <a href="/zips/55_Russian_H_color.zip">
        <img src="@/assets/components/design/download.png" alt="Скачать"/>
      </a>

      <div class="type">
        Полноцветный<br />
        инверсия
      </div>
      <img src="@/assets/components/design/logos/auto-russian/vertical-full-color-inversion.png" alt="Полноцветный вертикальный инверсия"/>
      <a href="/zips/52_Russian_V_color_invers.zip">
        <img src="@/assets/components/design/download.png" alt="Скачать"/>
      </a>
      <img src="@/assets/components/design/logos/auto-russian/horizontal-full-color-inversion.png" alt="Полноцветный горизонтальный инверсия"/>
      <a href="/zips/56_Russian_H_color_invers.zip">
        <img src="@/assets/components/design/download.png" alt="Скачать"/>
      </a>

      <div class="type">
        Чёрно-белый<br />
        инверсия
      </div>
      <img src="@/assets/components/design/logos/auto-russian/vertical-black-and-white-inversion.png" alt="Чёрно-белый вертикальный инверсия"/>
      <a href="/zips/53_Russian_V_black_invers.zip">
        <img src="@/assets/components/design/download.png" alt="Скачать"/>
      </a>
      <img src="@/assets/components/design/logos/auto-russian/horizontal-black-and-white-inversion.png" alt="Чёрно-белый горизонтальный инверсия"/>
      <a href="/zips/57_Russian_H_black_invers.zip">
        <img src="@/assets/components/design/download.png" alt="Скачать"/>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "DesignDirectionLogosAutoRussian"
}
</script>

<style lang="scss">
#design-direction-logos-auto-russian {
  width: 100%;

  &>p {
    width: 100%;
    padding-right: 25%;

    &.header {
      font-weight: 700;
      font-size: 20px;
      padding-right: 30%;
    }
  }

  .base-variant {
    width: 100%;
    margin-top: 35px;
    margin-bottom: 65px;
    padding-right: 25%;
    display: grid;
    grid-auto-flow: column;
    grid-template-rows: repeat(2, max-content);
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 35px;
    grid-column-gap: 55px;

    .type {
      font-weight: 300;
      font-size: 35px;
      line-height: 1.0;
    }

    &>img {
      max-width: 100%;
      margin: auto;
    }

    a {
      margin-left: auto;
      width: max-content;
      text-decoration: none;
    }
  }

  .colors-variant {
    width: 100%;
    margin-top: 55px;
    margin-bottom: 65px;
    padding-right: 25%;
    display: grid;
    grid-auto-flow: column;
    grid-template-rows: repeat(5, max-content);
    grid-template-columns: repeat(4, 1fr);
    grid-row-gap: 10px;
    grid-column-gap: 10px;

    .type {
      margin-bottom: 25px;
      font-weight: 300;
      font-size: 20px;
      line-height: 1.0;
      text-align: center;
    }

    &>img {
      max-width: 100%;
      margin: auto;
    }

    a {
      margin-left: auto;
      width: max-content;
      text-decoration: none;
    }
  }
}
</style>
