<template>
  <div id="brand-intro">
    <p>В группу компаний АВТОМОЁ входят бренды Авто-Кореец, Авто-Японец, Авто-Европеец, Авто-Китаец, Авто-Россиянин, Первый профессиональный агрегатный цех. АВТОМОЁ  тоже превратилось из просто названия группы компаний в обособленный бренд. Кроме того, существует еще и собственная торговая марка с одноименным названием AVTOMOE.</p>
    <p>Все эти бренды родились в Перми, по сей день именно там находится главный офис.</p>
    <iframe src="https://vk.com/video_ext.php?oid=327916243&id=456239201&hash=ff401bf2c5db81d1&hd=2" width="853" height="480" allow="autoplay; encrypted-media; fullscreen; picture-in-picture;" frameborder="0" allowfullscreen></iframe>
  </div>
</template>

<script>
export default {
  name: "BrandIntro"
}
</script>

<style lang="scss">
#brand-intro {
  margin-top: 80px;

  p {
    width: 100%;
    padding-right: 25%;
  }

  iframe {
    max-width: 100%;
    margin-top: 25px;
    margin-bottom: 35px;
  }
}
</style>
