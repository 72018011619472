<template>
  <div id="design">
    <section>
      <div class="header-with-menu">
        <h2>ДИЗАЙН</h2>
        <div class="menu">
          <ul>
            <li v-scroll-to="{el: '#design-logos', duration: 600, easing: 'easeInOutQuad', offset: -200}">Логотипы</li>
            <li v-scroll-to="{el: '#design-colors', duration: 600, easing: 'easeInOutQuad', offset: -200}">Цвета</li>
            <li v-scroll-to="{el: '#design-fonts', duration: 600, easing: 'easeInOutQuad', offset: -200}">Шрифты</li>
          </ul>
          <ul>
            <li v-scroll-to="{el: '#design-pattern', duration: 600, easing: 'easeInOutQuad', offset: -200}">Паттерн</li>
            <li v-scroll-to="{el: '#design-brand-character', duration: 600, easing: 'easeInOutQuad', offset: -200}">Персонаж бренда</li>
            <li v-scroll-to="{el: '#design-layouts', duration: 600, easing: 'easeInOutQuad', offset: -200}">Макеты</li>
          </ul>
        </div>
      </div>

      <p>Данное руководство представляет основные графические и цветовые элементы фирменного стиля нашей торговой марки, а также некоторые правила использования элементов на различных объектах - носителях визуальной информации.</p>
      <p>Чтобы обеспечить единство фирменного стиля на всех его носителях, рекомендуется использовать прилагаемые к данному руководству векторные файлы.</p>

      <DesignLogos v-inview="{component: 'Дизайн', element: 'Логотипы'}"/>
      <DesignColors v-inview="{component: 'Дизайн', element: 'Цвета'}"/>
      <DesignFonts v-inview="{component: 'Дизайн', element: 'Шрифты'}"/>
      <DesignPattern v-inview="{component: 'Дизайн', element: 'Паттерн'}"/>
      <DesignBrandCharacter v-inview="{component: 'Дизайн', element: 'Персонаж бренда'}"/>
      <DesignLayouts v-inview="{component: 'Дизайн', element: 'Макеты'}"/>
    </section>
  </div>
</template>

<script>
import DesignLogos from "@/components/design/DesignLogos"
import DesignColors from "@/components/design/DesignColors"
import DesignFonts from "@/components/design/DesignFonts"
import DesignPattern from "@/components/design/DesignPattern"
import DesignBrandCharacter from "@/components/design/DesignBrandCharacter"
import DesignLayouts from "@/components/design/DesignLayouts"

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Design",
  components: {
    DesignLogos,
    DesignColors,
    DesignFonts,
    DesignPattern,
    DesignBrandCharacter,
    DesignLayouts,
  },
}
</script>

<style lang="scss">
#design {

  section {
    padding-top: 55px;
    padding-bottom: 55px;

    &>p {
      width: 100%;
      padding-right: 35%;
    }
  }
}
</style>
