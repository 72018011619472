<template>
  <div id="interaction-brand-names">
    <h3>Названия брендов</h3>
    <p>Правильно пишите названия:</p>
    <div class="brand-names-grid">
      <div>
        <div class="description">Названия направлений <br />пишем через дефис <br />оба слова с большой буквы</div>
        <div class="example">Авто-Кореец <br />Авто-Японец <br />Авто-Европеец <br />Авто-Китаец <br />Авто-Россиянин</div>
      </div>
      <div>
        <div class="description">Название группы компаний <br />пишем заглавными буквами <br />без пробела</div>
        <div class="example">АВТОМОЁ</div>
      </div>
      <div>
        <div class="description">Допускается написание <br />латиницей, в случае упоминания <br />собственной торговой марки</div>
        <div class="example">AVTOMOE</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "InteractionBrandNames"
}
</script>

<style lang="scss">
#interaction-brand-names {
  width: 100%;

  h3 {
    margin-top: 80px;
    margin-bottom: 60px;
  }

  .brand-names-grid {
    width: 100%;
    margin-top: 35px;
    padding-right: 30%;
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;

    .description {
      margin-bottom: 15px;
    }

    .example {
      font-weight: 500;
      font-size: 25px;
    }
  }
}
</style>
