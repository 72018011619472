<template>
  <div id="design-logos">
    <h3>Логотипы</h3>

    <div class="tabs">
      <div
        class="tab"
        :class="{active: mainLogosVariant === 'main'}"
        @click="showMainLogosVariant('main')"
      >
        Основной логотип ГК
      </div>
      <div
        class="tab"
        :class="{active: mainLogosVariant === 'alt'}"
        @click="showMainLogosVariant('alt')"
      >
        Альтернативный логотип ГК
      </div>
    </div>

    <DesignMainLogosMain v-if="mainLogosVariant === 'main'"/>
    <DesignMainLogosAlt v-if="mainLogosVariant === 'alt'"/>

    <h4>Логотипы направлений гипермаркетов:</h4>

    <div class="tabs">
      <div
          class="tab"
          :class="{active: directionLogosVariant === 'Auto-Korean'}"
          @click="showDirectionLogosVariant('Auto-Korean')"
      >
        Авто-Кореец
      </div>
      <div
          class="tab"
          :class="{active: directionLogosVariant === 'Auto-Japanese'}"
          @click="showDirectionLogosVariant('Auto-Japanese')"
      >
        Авто-Японец
      </div>
      <div
          class="tab"
          :class="{active: directionLogosVariant === 'Auto-European'}"
          @click="showDirectionLogosVariant('Auto-European')"
      >
        Авто-Европеец
      </div>
      <div
          class="tab"
          :class="{active: directionLogosVariant === 'Auto-Chinese'}"
          @click="showDirectionLogosVariant('Auto-Chinese')"
      >
        Авто-Китаец
      </div>
      <div
          class="tab"
          :class="{active: directionLogosVariant === 'Auto-Russian'}"
          @click="showDirectionLogosVariant('Auto-Russian')"
      >
        Авто-Россиянин
      </div>
    </div>

    <DesignDirectionLogosAutoKorean v-if="directionLogosVariant === 'Auto-Korean'"/>
    <DesignDirectionLogosAutoJapanese v-if="directionLogosVariant === 'Auto-Japanese'"/>
    <DesignDirectionLogosAutoEuropean v-if="directionLogosVariant === 'Auto-European'"/>
    <DesignDirectionLogosAutoChinese v-if="directionLogosVariant === 'Auto-Chinese'"/>
    <DesignDirectionLogosAutoRussian v-if="directionLogosVariant === 'Auto-Russian'"/>

    <h4>Логотипы сервисных направлений:</h4>

    <div class="tabs">
      <div
          class="tab"
          :class="{active: servicesLogosVariant === 'service'}"
          @click="showServicesLogosVariant('service')"
      >
        Официальный автосервис
      </div>
      <div
          class="tab"
          :class="{active: servicesLogosVariant === 'workshop'}"
          @click="showServicesLogosVariant('workshop')"
      >
        Первый многофункциональный агрегатный цех
      </div>
    </div>

    <DesignServicesLogosService v-if="servicesLogosVariant === 'service'"/>
    <DesignServicesLogosWorkshop v-if="servicesLogosVariant === 'workshop'"/>

    <h4>ЗАПРЕЩЕНО ИСПОЛЬЗОВАНИЕ ДРУГИХ ВАРИАЦИЙ ЛОГОТИПОВ!</h4>

    <p><b>Изменение цветов, шрифтов, расположения элементов, <br />размеров не пропорционально (растягивание / сжатие) <br />НЕ ДОПУСТИМО!</b></p>

    <p>Так же стоит помнить что у любого из представленных логотипов обязательно есть охранная зона, -  область вокруг лого, в которой не должно быть никаких других графических элементов, текста, краев макета. Охранная зона логотипа не должна быть меньше буквы «А» в фирменном написании.</p>

    <img src="@/assets/components/design/logos/protected-zone.jpg" alt="Охранная зона логотипа"/>
  </div>
</template>

<script>
import DesignMainLogosMain from "@/components/design/design-logos/DesignMainLogosMain"
import DesignMainLogosAlt from "@/components/design/design-logos/DesignMainLogosAlt"
import DesignDirectionLogosAutoKorean from "@/components/design/design-logos/DesignDirectionLogosAutoKorean"
import DesignDirectionLogosAutoJapanese from "@/components/design/design-logos/DesignDirectionLogosAutoJapanese"
import DesignDirectionLogosAutoEuropean from "@/components/design/design-logos/DesignDirectionLogosAutoEuropean"
import DesignDirectionLogosAutoChinese from "@/components/design/design-logos/DesignDirectionLogosAutoChinese"
import DesignDirectionLogosAutoRussian from "@/components/design/design-logos/DesignDirectionLogosAutoRussian"
import DesignServicesLogosService from "@/components/design/design-logos/DesignServicesLogosService"
import DesignServicesLogosWorkshop from "@/components/design/design-logos/DesignServicesLogosWorkshop"

export default {
  name: "DesignLogos",
  components: {
    DesignMainLogosMain,
    DesignMainLogosAlt,
    DesignDirectionLogosAutoKorean,
    DesignDirectionLogosAutoJapanese,
    DesignDirectionLogosAutoEuropean,
    DesignDirectionLogosAutoChinese,
    DesignDirectionLogosAutoRussian,
    DesignServicesLogosService,
    DesignServicesLogosWorkshop,
  },
  data() {
    return {
      mainLogosVariant: 'main',
      directionLogosVariant: 'Auto-Korean',
      servicesLogosVariant: 'service',
    }
  },
  methods: {
    showMainLogosVariant(variant) {
      this.mainLogosVariant = variant
    },
    showDirectionLogosVariant(variant) {
      this.directionLogosVariant = variant
    },
    showServicesLogosVariant(variant) {
      this.servicesLogosVariant = variant
    },
  },
}
</script>

<style lang="scss">
#design-logos {
  width: 100%;
  margin-top: 95px;

  h3 {
    margin-bottom: 75px;
  }

  .tabs {
    width: 100%;
    margin-bottom: 35px;
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(5, max-content);
    grid-gap: 35px;

    .tab {
      padding-bottom: 8px;
      border-bottom: 2px solid transparent;
      font-weight: 700;
      font-size: 28px;
      line-height: 1;
      color: #D9DADA;
      cursor: pointer;

      &.active {
        border-bottom-color: red;
        color: #5B5B5B;
      }
    }
  }

  &>p {
    width: 100%;
    padding-right: 25%;
  }
}
</style>
