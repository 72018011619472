<template>
  <div id="interaction">
    <section>
      <div class="header-with-menu">
        <h2>ВЗАИМОДЕЙСТВИЕ</h2>
        <div class="menu">
          <ul>
            <li v-scroll-to="{el: '#interaction-partnership', duration: 600, easing: 'easeInOutQuad', offset: -200}">Партнерство</li>
            <li v-scroll-to="{el: '#interaction-brand-names', duration: 600, easing: 'easeInOutQuad', offset: -200}">Названия брендов</li>
            <li v-scroll-to="{el: '#interaction-formulations', duration: 600, easing: 'easeInOutQuad', offset: -200}">Формулировки</li>
          </ul>
          <ul>
            <li v-scroll-to="{el: '#interaction-communication', duration: 600, easing: 'easeInOutQuad', offset: -200}">Общение</li>
            <li v-scroll-to="{el: '#interaction-promotions', duration: 600, easing: 'easeInOutQuad', offset: -200}">Акции</li>
          </ul>
        </div>
      </div>

      <InteractionPartnership v-inview="{component: 'Взаимодействие', element: 'Партнерство'}"/>
      <InteractionBrandNames v-inview="{component: 'Взаимодействие', element: 'Названия брендов'}"/>
      <InteractionFormulations v-inview="{component: 'Взаимодействие', element: 'Формулировки'}"/>
      <InteractionCommunication v-inview="{component: 'Взаимодействие', element: 'Общение'}"/>
      <InteractionPromotions v-inview="{component: 'Взаимодействие', element: 'Акции'}"/>
    </section>
  </div>
</template>

<script>
import InteractionPartnership from "@/components/interaction/InteractionPartnership"
import InteractionBrandNames from "@/components/interaction/InteractionBrandNames"
import InteractionFormulations from "@/components/interaction/InteractionFormulations"
import InteractionCommunication from "@/components/interaction/InteractionCommunication"
import InteractionPromotions from "@/components/interaction/InteractionPromotions"

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Interaction",
  components: {
    InteractionPartnership,
    InteractionBrandNames,
    InteractionFormulations,
    InteractionCommunication,
    InteractionPromotions,
  },
}
</script>

<style lang="scss">
#interaction {
  width: 100%;

  section {
    padding-top: 55px;
    padding-bottom: 55px;
  }
}
</style>
