<template>
  <div id="design-layouts">
    <h3 >Макеты</h3>

    <p>Все макеты можно условно разделить две категории, корпоративные и рекламные материалы.</p>

    <p class="header">Примеры корпоративных материалов:</p>

    <div class="layouts-box">
      <div class="name">
        Корпоративная<br />
        визитка
      </div>
      <img src="@/assets/components/design/design-layouts/corporate-business-card.png" alt="Корпоративная визитка"/>
      <div class="download">
        <img src="@/assets/components/design/download.png" alt="Скачать"/>
        <DownloadPopUp>
          Материалы готовятся.
        </DownloadPopUp>
      </div>

      <div class="name">
        Дисконтная<br />
        карта
      </div>
      <img src="@/assets/components/design/design-layouts/discount-card.png" alt="Дисконтная карта"/>
      <div class="download">
        <img src="@/assets/components/design/download.png" alt="Скачать"/>
        <DownloadPopUp>
          Материалы готовятся.
        </DownloadPopUp>
      </div>

      <div class="name">
        Персональная<br />
        визитка
      </div>
      <img src="@/assets/components/design/design-layouts/personal-business-card.png" alt="Персональная визитка"/>
      <div class="download">
        <img src="@/assets/components/design/download.png" alt="Скачать"/>
        <DownloadPopUp>
          Материалы готовятся.
        </DownloadPopUp>
      </div>

      <div class="name">
        Бейдж
      </div>
      <img src="@/assets/components/design/design-layouts/badge.png" alt="Бейдж"/>
      <div class="download">
        <img src="@/assets/components/design/download.png" alt="Скачать"/>
        <DownloadPopUp>
          <div>АВТОМОЁ <small>[</small><a href="/zips/badge_avtomoe.zip">скачать</a><small>]</small></div>
          <div>Авто-Кореец <small>[</small><a href="/zips/badge_Korean.zip">скачать</a><small>]</small></div>
          <div>Авто-Японец <small>[</small><a href="/zips/badge_Japanese.zip">скачать</a><small>]</small></div>
          <div>Авто-Европеец <small>[</small><a href="/zips/badge_Europian.zip">скачать</a><small>]</small></div>
          <div>Авто-Китаец <small>[</small><a href="/zips/badge_Chinese.zip">скачать</a><small>]</small></div>
          <div>Авто-Россиянин <small>[</small><a href="/zips/badge_Russian.zip">скачать</a><small>]</small></div>
          <div>Сервис <small>[</small><a href="/zips/badge_service.zip">скачать</a><small>]</small></div>
        </DownloadPopUp>
      </div>

      <div class="name">
        Визитка<br />
        направления
      </div>
      <img src="@/assets/components/design/design-layouts/business-card.png" alt="Визитка направления"/>
      <div class="download">
        <img src="@/assets/components/design/download.png" alt="Скачать"/>
        <DownloadPopUp>
          Материалы готовятся.
        </DownloadPopUp>
      </div>

      <div class="name">
        Выклейка<br />
        автомобиля<br />
        <span>Lada Largus</span>
      </div>
      <img src="@/assets/components/design/design-layouts/car-branding.png" alt="Выклейка автомобиля"/>
      <div class="download">
        <img src="@/assets/components/design/download.png" alt="Скачать"/>
        <DownloadPopUp>
          Материалы готовятся.
        </DownloadPopUp>
      </div>
    </div>

    <p class="header">Рекомендации по изготовлению акционных материалов:</p>

    <div class="recommendations">
      <p class="header">Общие дизайн-принципы</p>
      <p>Создавая макеты, мы следуем определенному набору правил или, как мы их называем, принципов.</p>
      <ul>
        <li>Создаем макеты с учетом системы якорей: синего цвета, набора фирменных иконок, шрифтовой системы и системы сеток.</li>
        <li>Мы любим не перегруженную, легкую для восприятия верстку: один носитель — одно сообщение.</li>
        <li>Предпочитаем эмоциональный, но простой и понятный текст.</li>
        <li>Создаем фотографии и иллюстрации со смыслом, и любим, когда в них заложена идея.</li>
      </ul>
    </div>

    <div id="carousel">
      <div id="drag-container">
        <div id="spin-container">
          <a><img src="@/assets/components/design/design-layouts/gallery/layouts-gallery-01.jpg" alt="" /></a>
          <a><img src="@/assets/components/design/design-layouts/gallery/layouts-gallery-02.jpg" alt="" /></a>
          <a><img src="@/assets/components/design/design-layouts/gallery/layouts-gallery-03.jpg" alt="" /></a>
          <a><img src="@/assets/components/design/design-layouts/gallery/layouts-gallery-04.jpg" alt="" /></a>
          <a><img src="@/assets/components/design/design-layouts/gallery/layouts-gallery-05.jpg" alt="" /></a>
          <a><img src="@/assets/components/design/design-layouts/gallery/layouts-gallery-06.jpg" alt="" /></a>
        </div>
        <div id="ground"></div>
      </div>
    </div>

  </div>
</template>

<script>
import DownloadPopUp from "@/components/common/DownloadPopUp"

export default {
  name: "DesignLayouts",
  components: {
    DownloadPopUp
  },
  methods: {
    initCarousel() {
      let radius = 340;
      let autoRotate = true;
      let rotateSpeed = -60;
      let imgWidth = 200;
      let imgHeight = 275;
      setTimeout(init, 300);
      let odrag = document.getElementById("drag-container");
      let ospin = document.getElementById("spin-container");
      let carousel = document.getElementById("carousel");
      let aImg = ospin.getElementsByTagName("a");
      ospin.style.width = imgWidth + "px";
      ospin.style.height = imgHeight + "px";
      let ground = document.getElementById("ground");
      ground.style.width = radius * 3 + "px";
      ground.style.height = radius * 3 + "px";

      function init(delayTime) {
        for (let i = 0; i < aImg.length; i++) {
          aImg[i].style.transform = "rotateY(" + i * (360 / aImg.length) + "deg) translateZ(" + radius + "px)";
          aImg[i].style.transition = "transform 1s";
          aImg[i].style.transitionDelay = delayTime || (aImg.length - i) / 4 + "s";
        }
      }

      function applyTranform(obj) {
        if (tY > 180) tY = 180;
        if (tY < 0) tY = 0;
        obj.style.transform = "rotateX(" + -tY + "deg) rotateY(" + tX + "deg)";
      }

      function playSpin(yes) {
        ospin.style.animationPlayState = yes ? "running" : "paused";
      }

      let desX = 0, desY = 0, tX = 0, tY = 10;

      if (autoRotate) {
        let animationName = rotateSpeed > 0 ? "spin" : "spinRevert";
        ospin.style.animation = `${animationName} ${Math.abs(rotateSpeed)}s infinite linear`;
      }

      carousel.onpointerdown = function(e) {
        clearInterval(odrag.timer);
        e = e || window.event;
        let sX = e.clientX, sY = e.clientY;
        this.onpointermove = function(e) {
          e = e || window.event;
          let nX = e.clientX, nY = e.clientY;
          desX = nX - sX;
          desY = nY - sY;
          tX += desX * 0.1;
          tY += desY * 0.1;
          applyTranform(odrag);
          sX = nX;
          sY = nY;
        };
        this.onpointerup = function() {
          odrag.timer = setInterval(function() {
            desX *= 0.95;
            desY *= 0.95;
            tX += desX * 0.1;
            tY += desY * 0.1;
            applyTranform(odrag);
            playSpin(false);
            if (Math.abs(desX) < 0.5 && Math.abs(desY) < 0.5) {
              clearInterval(odrag.timer);
              playSpin(true);
            }
          }, 17);
          this.onpointermove = this.onpointerup = null;
        };
        return false;
      };
    }
  },
  mounted() {
    this.initCarousel()
  }
}
</script>

<style lang="scss">
#design-layouts {
  width: 100%;
  margin-top: 95px;

  h3 {

  }

  &>p {
    width: 100%;
    padding-right: 25%;

    &.header {
      font-weight: 700;
      font-size: 20px;
      padding-right: 30%;
    }
  }

  .layouts-box {
    width: 100%;
    margin-top: 35px;
    margin-bottom: 65px;
    padding-right: 25%;
    display: grid;
    grid-auto-flow: column;
    grid-template-rows: repeat(6, max-content);
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: 35px;
    grid-column-gap: 25px;

    .name {
      font-weight: 300;
      font-size: 25px;
      line-height: 1.0;
      color: #054667;

      span {
        font-size: 12px;
        font-weight: 100;
      }
    }

    &>img {
      max-width: 100%;
      margin: auto;
    }

    .download {
      position: relative;
      margin-left: auto;
      width: max-content;
      text-decoration: none;
    }
  }

  .recommendations {
    width: 75%;
    margin-top: 45px;
    padding: 20px 40px;
    background-color: #3FABD9;
    box-shadow: 0px 6px 12px 2px rgba(34, 60, 80, 0.2);
    color: white;

    p {

      &.header {
        font-weight: 700;
      }
    }

    ul {
      padding-left: 20px;

      li {
        margin-bottom: 15px;
        list-style-type: square;
      }
    }
  }
}

/* Карусель */
#carousel  {
  width: 75%;
  margin-top: 55px;
  margin-bottom: 90px;
  touch-action: none;
  display: flex;
  height: 400px;
  perspective: 2000px;
  transform-style: preserve-3d;
}
#drag-container,
#spin-container {
  position: relative;
  display: flex;
  margin: auto;
  transform-style: preserve-3d;
  transform: rotateX(-10deg);
}
#drag-container a {
  transform-style: preserve-3d;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 4px 8px rgba(0,0,0,0.2), 0 10px 16px rgba(0,0,0,0.1);
  //border: 8px solid #BFE2FF;
}
#drag-container img {
  width: 100%;
  height: 100%;
}

#drag-container p {
  line-height: 1;
  font-size: 50px;
  font-weight: bold;
  text-align: center;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -50%) rotateX(90deg);
  color: #337AB7;
}

#ground {
  width: 900px;
  height: 900px;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -50%) rotateX(90deg);
}
@keyframes spin {
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(360deg);
  }
}
@keyframes spinRevert {
  from {
    transform: rotateY(360deg);
  }
  to {
    transform: rotateY(0deg);
  }
}
</style>
