<template>
  <div id="design-main-logos-alt">
    <div class="base-horizontal-variant">
      <div class="description">Альтернативной версией логотипа группы компаний является прошлая версия с флагами.</div>
      <img src="@/assets/components/design/logos/alt/main-company-group-horizontal.png" alt="Альтернативная версия горизонтальная"/>

      <div class="description">Так же, существует версия с дескриптором «ЗАПЧАСТИ И СЕРВИС». Подходит для случаев, когда  необходимо обозначить причастность не только к сфере автотоваров но и услуг по ремонту автомобиля.</div>
      <img src="@/assets/components/design/logos/alt/main-parts-and-service-horizontal.png" alt="Альтернативная версия ЗАПЧАСТИ И СЕРВИС горизонтальная"/>
    </div>

    <p class="header">Вертикальный логотип</p>

    <div class="base-vertical-variant">
      <img src="@/assets/components/design/logos/alt/main-company-group-vertical.png" alt="Альтернативная версия вертикальная"/>
      <img src="@/assets/components/design/logos/alt/main-parts-and-service-vertical.png" alt="Альтернативная версия ЗАПЧАСТИ И СЕРВИС вертикальная"/>
    </div>

    <p class="header">У данных логотипов существует 4 цветовые вариации:</p>

    <div class="colors-variant">
      <div class="type">
        Чёрно-белый
      </div>
      <img src="@/assets/components/design/logos/alt/company-group-vertical-black-and-white.png" alt="Чёрно-белый"/>
      <a href="/zips/10_company-group-vertical-black-and-white.zip">
        <img src="@/assets/components/design/download.png" alt="Скачать"/>
      </a>
      <img src="@/assets/components/design/logos/alt/company-group-horizontal-black-and-white.png" alt="Чёрно-белый"/>
      <a href="/zips/14_company-group-horizontal-black-and-white.zip">
        <img src="@/assets/components/design/download.png" alt="Скачать"/>
      </a>

      <div class="type">
        Полноцветный
      </div>
      <img src="@/assets/components/design/logos/alt/company-group-vertical-full-color.png" alt="Полноцветный"/>
      <a href="/zips/11_company-group-vertical-full-color.zip">
        <img src="@/assets/components/design/download.png" alt="Скачать"/>
      </a>
      <img src="@/assets/components/design/logos/alt/company-group-horizontal-full-color.png" alt="Полноцветный"/>
      <a href="/zips/15_company-group-horizontal-full-color.zip">
        <img src="@/assets/components/design/download.png" alt="Скачать"/>
      </a>

      <div class="type">
        Полноцветный<br />
        инверсия
      </div>
      <img src="@/assets/components/design/logos/alt/company-group-vertical-full-color-inversion.png" alt="Полноцветный инверсия"/>
      <a href="/zips/12_company-group-vertical-full-color-inversion.zip">
        <img src="@/assets/components/design/download.png" alt="Скачать"/>
      </a>
      <img src="@/assets/components/design/logos/alt/company-group-horizontal-full-color-inversion.png" alt="Полноцветный инверсия"/>
      <a href="/zips/16_company-group-horizontal-full-color-inversion.zip">
        <img src="@/assets/components/design/download.png" alt="Скачать"/>
      </a>

      <div class="type">
        Чёрно-белый<br />
        инверсия
      </div>
      <img src="@/assets/components/design/logos/alt/company-group-vertical-black-and-white-inversion.png" alt="Чёрно-белый инверсия"/>
      <a href="/zips/13_company-group-vertical-black-and-white-inversion.zip">
        <img src="@/assets/components/design/download.png" alt="Скачать"/>
      </a>
      <img src="@/assets/components/design/logos/alt/company-group-horizontal-black-and-white-inversion.png" alt="Чёрно-белый инверсия"/>
      <a href="/zips/17_company-group-horizontal-black-and-white-inversion.zip">
        <img src="@/assets/components/design/download.png" alt="Скачать"/>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "DesignMainLogosAlt"
}
</script>

<style lang="scss">
#design-main-logos-alt {
  width: 100%;

  .base-horizontal-variant {
    width: 100%;
    margin-bottom: 65px;
    padding-right: 25%;
    display: grid;
    grid-auto-flow: column;
    grid-template-rows: repeat(2, max-content);
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 35px;
    grid-column-gap: 55px;

    &>img {
      max-width: 100%;
    }

    a {
      margin-left: auto;
      width: max-content;
      text-decoration: none;
    }
  }

  &>p {
    width: 100%;
    padding-right: 45%;

    &.header {
      font-weight: 700;
      font-size: 20px;
      padding-right: 30%;
    }
  }

  .base-vertical-variant {
    width: 100%;
    margin-top: 55px;
    margin-bottom: 65px;
    padding-right: 25%;
    display: grid;
    grid-auto-flow: column;
    grid-template-rows: repeat(1, max-content);
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 35px;
    grid-column-gap: 55px;

    &>img {
      max-width: 100%;
      margin: auto;
    }

    a {
      margin-left: auto;
      width: max-content;
      text-decoration: none;
    }
  }

  .colors-variant {
    width: 100%;
    margin-top: 55px;
    margin-bottom: 65px;
    padding-right: 25%;
    display: grid;
    grid-auto-flow: column;
    grid-template-rows: repeat(5, max-content);
    grid-template-columns: repeat(4, 1fr);
    grid-row-gap: 10px;
    grid-column-gap: 10px;

    .type {
      margin-bottom: 25px;
      font-weight: 300;
      font-size: 20px;
      line-height: 1.0;
      text-align: center;
    }

    &>img {
      max-width: 100%;
      margin: auto;
    }

    a {
      margin-bottom: 10px;
      margin-left: auto;
      width: max-content;
      text-decoration: none;
    }
  }
}
</style>
