<template>
  <div id="corporate-style-clothing">
    <h3>Корпоративная одежда</h3>

    <div class="clothing-box">
      <img src="@/assets/components/corporate-style/clothing/clothing.png" alt="Корпоративная одежда">
      <div class="description">
        <p>Сотрудник является лицом компании и должен отражать ее корпоративную культуру. Утвержден единый формат корпоративной одежды.</p>
        <p>Рубашка темно синяя с коротким или длинным рукавом. Темные брюки. Ботинки или туфли не спортивного стиля. Обязательно наличие бейджа утвержденного образца.</p>
        <p>Не допускается: шорты, футболки, кроссовки. Наличие верхней одежды допускается только в исключительных случаях.</p>
        <p class="header">Впечатления от сотрудника на рабочем месте:</p>
        <p>Не допускается запах табака от сотрудника, жевательные резинки, еда на рабочем месте, личные вещи сотрудника на рабочем месте.</p>
        <p>Запрещен мат и ругательные выражения. Сотрудник доброжелателен, вежлив и настроен на решение проблем с автомобилем клиента.</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CorporateStyleClothing"
}
</script>

<style lang="scss">
#corporate-style-clothing {
  width: 100%;

  h3 {

  }

  .clothing-box {
    width: 100%;
    padding-right: 35%;
    display: grid;
    grid-auto-flow: row;
    grid-template-rows: max-content;
    grid-template-columns: 300px 1fr;
    grid-column-gap: 55px;

    img {
      max-width: 100%;
      margin: auto;
    }

    p {

      &.header {
        font-weight: 700;
      }
    }
  }
}
</style>
