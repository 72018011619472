<template>
  <div id="design-pattern">
    <h3>Паттерн</h3>

    <div class="pattern-box">
      <div class="description">
        <p>Для оформления, рекламной, имиджевой, или сувенирной продукции возможно использование паттерна.</p>
        <p>В качестве патерна используется контурное изображение флагов из логотипов направлений.</p>
      </div>
      <div class="image">
        <img src="@/assets/components/design/pattern/pattern.png" alt="Паттерн">
        <a href="/zips/67_Pattern.zip">
          <img src="@/assets/components/design/download.png" alt="Скачать"/>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DesignPattern"
}
</script>

<style lang="scss">
#design-pattern {
  width: 100%;
  margin-top: 95px;

  h3 {

  }

  .pattern-box {
    width: 100%;
    padding-right: 25%;
    display: flex;
    flex-direction: row;

    .description {
      width: 50%;
      padding-right: 25px;
    }

    .image {
      display: flex;
      flex-direction: column;
      flex-grow: 1;

      &>img {
        max-width: 100%;
        margin-bottom: 15px;
      }

      a {
        margin-left: auto;
        width: max-content;
        text-decoration: none;
      }
    }
  }
}
</style>
