<template>
  <div id="design-colors">
    <h3>Цвета</h3>

    <p>Синий - главный цветовой идентификатор бренда. В случаях, когда мы хотим сообщить, что «это мы» — красим синим максимум площади. Белый отлично подходит для текста, реже для фона, красный для выделения цен или каких то акцентов.</p>

    <div class="colors-box">
      <div class="item">
        <div class="values color-blue">
          CMYK: 100.72.20.5<br />
          RGB: 33.75.134<br />
          #214B86
        </div>
        <div class="name">Синий</div>
      </div>
      <div class="item">
        <div class="values color-red">
          CMYK: 0.100.100.20<br />
          RGB: 166.0.0<br />
          #A60000
        </div>
        <div class="name">Красный</div>
      </div>
      <div class="item">
        <div class="values color-light-blue">
          CMYK: 100.17.7.0<br />
          RGB: 0.143.203<br />
          #008FCB
        </div>
        <div class="name">Голубой</div>
      </div>
      <div class="item">
        <div class="values color-black">
          CMYK: 50.50.50.100<br />
          RGB: 0.0.0<br />
          #000000
        </div>
        <div class="name">Черный</div>
      </div>
      <div class="item">
        <div class="values color-white">
          CMYK: 0.0.0.0<br />
          RGB: 255.255.255<br />
          #FFFFFF
        </div>
        <div class="name">Белый</div>
      </div>
      <div class="item">
        <div class="values color-grayscale-blue">
          CMYK: 33.9.7.0<br />
          RGB: 189.210.227<br />
          #BDD2E3
        </div>
        <div class="name">Серо-голубой</div>
      </div>
    </div>

    <div class="gradient-box">
      <div class="gradient red"></div>
      <div class="values">
        <div>
          <img src="@/assets/components/design/colors/gradient-red-from.png" alt="Градиент красный (от)"/>
          <span>cmyk: 0.100.100.0 (#FF0000)</span>
        </div>
        <div>
          <img src="@/assets/components/design/colors/gradient-red-to.png" alt="Градиент красный (до)"/>
          <span>cmyk: 0.75.100.0 (#C02222)</span>
        </div>
      </div>
    </div>
    <div class="gradient-box">
      <div class="gradient light-blue"></div>
      <div class="values">
        <div>
          <img src="@/assets/components/design/colors/gradient-light-blue-from.png" alt="Градиент голубой (от)"/>
          <span>cmyk: 0.0.0.0 (#FFFFFF)</span>
        </div>
        <div>
          <img src="@/assets/components/design/colors/gradient-light-blue-to.png" alt="Градиент голубой (до)"/>
          <span>cmyk: 33.9.7.0 (#B5D3E5)</span>
        </div>
      </div>
    </div>
    <div class="gradient-box">
      <div class="gradient blue"></div>
      <div class="values">
        <div>
          <img src="@/assets/components/design/colors/gradient-blue-from.png" alt="Градиент синий (от)"/>
          <span>cmyk: 100.67.15.4 (#065190)</span>
        </div>
        <div>
          <img src="@/assets/components/design/colors/gradient-blue-to.png" alt="Градиент синий (до)"/>
          <span>cmyk: 100.93.30.10 (#212A6E)</span>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "DesignColors"
}
</script>

<style lang="scss">
#design-colors {
  width: 100%;
  margin-top: 95px;

  h3 {}

  &>p {
    width: 100%;
    padding-right: 20%;
  }

  .colors-box {
    width: 100%;
    margin-top: 55px;
    margin-bottom: 85px;
    padding-right: 25%;
    display: grid;
    grid-auto-flow: row;
    grid-template-rows: max-content max-content;
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: 20px;
    grid-column-gap: 20px;

    .item {
      box-shadow: 0 6px 12px 2px rgba(34, 60, 80, 0.2);
      border-radius: 8px;

      .values {
        width: 100%;
        padding: 25px 25px 70px 25px;
        border-radius: 8px 8px 0 0;
        color: white;

        &.color-blue {
          background-color: #214B86;
        }

        &.color-red {
          background-color: #A60000;
        }

        &.color-light-blue {
          background-color: #008FCB;
        }

        &.color-black {
          background-color: #000000;
        }

        &.color-white {
          background-color: #FFFFFF;
          color: gray;
        }

        &.color-grayscale-blue {
          background-color: #BDD2E3;
        }
      }

      .name {
        width: 100%;
        padding: 15px 15px 20px;
        font-size: 35px;
        font-weight: 300;
      }
    }
  }

  .gradient-box {
    width: 100%;
    padding-right: 25%;
    box-sizing: border-box;

    .gradient {
      height: 25px;
      margin: 0 7px;
      box-sizing: border-box;
      border: 1px solid rgba(128, 128, 128, 0.35);

      &.red {
        background: linear-gradient(to right, #FF0000, #C02222);
      }

      &.light-blue {
        background: linear-gradient(to right, #FFFFFF, #B5D3E5);
      }

      &.blue {
        background: linear-gradient(to right, #065190, #212A6E);
      }
    }

    .values {
      width: 100%;
      margin-bottom: 25px;
      display: flex;
      flex-direction: row;

      div {
        width: 50%;
        display: flex;
        flex-direction: column;

        img {
          width: 14px;
        }

        &:nth-child(2) {
          align-items: flex-end;
        }
      }
    }
  }
}
</style>
