<template>
  <div id="interaction-communication">
    <h3>Общение</h3>
    <p>Допускается обращение к клиенту на «ты» только в рекламных материалах, но необходимо придерживаться единого формата обращения во всем документе.</p>
    <p>Например:</p>
    <div class="communication-grid">
      <div class="header error">Нет</div>
      <div class="example"><b>Совершите</b> покупку на сумму более 3000р и <b>получи</b> возможность выиграть PlayStation5.</div>

      <div class="header success">Да</div>
      <div class="example"><b>Соверши</b> покупку на сумму более 3000р и <b>получи</b> возможность выиграть PlayStation5.</div>

      <div></div>
      <div class="example">Глаголы в предложении необходимо употреблять в одном лице.</div>
    </div>

    <p class="header">Не допускается обращение на «ты» в официальных письмах, личной переписке с клиентами.</p>

    <div class="etiquette">
      <div>
        <img src="@/assets/components/interaction/visitor.jpg" alt="Общения с посетителями магазина">
      </div>
      <div>
        <div class="type">Этикет общения с посетителями магазина:</div>
        <ol>
          <li>В любой ситуации оставаться вежливым</li>
          <li>Не использовать сленг</li>
          <li>Обращаться на «вы»</li>
          <li>Демонстрировать доброжелательность</li>
          <li>Не используем слово клиент</li>
          <li>Не говорим «нет»</li>
        </ol>
      </div>
    </div>
    <div class="etiquette">
      <div>
        <img src="@/assets/components/interaction/chat.jpg" alt="Общения в чатах и мессенджерах">
      </div>
      <div>
        <div class="type">Этикет общения в чатах и мессенджерах:</div>
        <ol>
          <li>Обращаемся на «вы» с маленькой буквы</li>
          <li>Обязательно приветствие в начале и прощание при завершении диалога</li>
          <li>Не оставлять диалог без ответа</li>
          <li>Задержка в диалоге на сайте максимум 2 минуты, в вк максимум 5 мин</li>
        </ol>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "InteractionCommunication"
}
</script>

<style lang="scss">
#interaction-communication {
  width: 100%;

  h3 {
    margin-top: 80px;
    margin-bottom: 60px;
  }

  p {
    width: 100%;
    padding-right: 40%;

    &.header {
      font-weight: 500;
    }
  }

  .communication-grid {
    width: 100%;
    margin-top: 35px;
    padding-right: 35%;
    display: grid;
    grid-auto-flow: column;
    grid-template-rows: repeat(2, max-content);
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: 10px;
    grid-column-gap: 35px;

    .header {
      margin-bottom: 15px;
      font-weight: 500;
      font-size: 30px;

      &.error {
        color: red;
      }

      &.success {
        color: #05547d;
      }
    }

    .exception {
      margin-top: 56px;
      font-weight: 700;
    }
  }

  .etiquette {
    width: 100%;
    margin-top: 50px;
    padding-right: 40%;
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 265px 1fr;
    grid-gap: 40px;

    img {
      max-width: 100%;
    }

    .type {
      margin-bottom: 12px;
      font-weight: 500;
    }

    ol {
      margin: 0;
      padding-left: 18px;
    }
  }
}
</style>
