<template>
  <div id="audience">
    <section>
      <div class="header-with-menu">
        <h2>АУДИТОРИЯ</h2>
        <div class="menu">
          <ul>
            <li v-scroll-to="{el: '#audience-segments', duration: 600, easing: 'easeInOutQuad', offset: -200}">Сегменты</li>
            <li v-scroll-to="{el: '#audience-consumption-moments', duration: 600, easing: 'easeInOutQuad', offset: -200}">Моменты потребления</li>
          </ul>
        </div>
      </div>

      <AudienceSegments v-inview="{component: 'Аудитория', element: 'Сегменты аудитории'}"/>
      <AudienceConsumptionMoments v-inview="{component: 'Аудитория', element: 'Моменты потребления'}"/>
    </section>
  </div>
</template>

<script>
import AudienceSegments from "@/components/audience/AudienceSegments";
import AudienceConsumptionMoments from "@/components/audience/AudienceConsumptionMoments";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Audience",
  components: {
    AudienceSegments,
    AudienceConsumptionMoments
  },
}
</script>

<style lang="scss">
#audience {

  section {
    padding-top: 55px;
    padding-bottom: 55px;
  }
}
</style>
