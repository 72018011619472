<template>
  <div id="app">
    <div
        class="to-top"
        v-scroll="handleScroll"
        v-scroll-to="{el: '#jumbotron', duration: 800, easing: 'easeInOutQuad'}"
    />
    <Jumbotron />
    <Breadcrumbs />
    <Brand />
    <Audience />
    <Interaction />
    <Design />
    <CorporateStyle />
  </div>
</template>

<script>
import Jumbotron from '@/components/jumbotron/Jumbotron'
import Breadcrumbs from '@/components/breadcrumbs/Breadcrumbs'
import Brand from '@/components/brand/Brand'
import Audience from '@/components/audience/Audience'
import Interaction from '@/components/interaction/Interaction'
import Design from '@/components/design/Design'
import CorporateStyle from '@/components/corporate-style/CorporateStyle'

export default {
  name: 'App',
  components: {
    Jumbotron,
    Breadcrumbs,
    Brand,
    Audience,
    Interaction,
    Design,
    CorporateStyle,
  },
  methods: {
    handleScroll: function (evt, el) {
      if (window.scrollY > 1000) {
        el.setAttribute(
            'style',
            'opacity: 0.7; transform: translate3d(0, -10px, 0);'
        )
      } else {
        el.setAttribute(
            'style',
            'opacity:0;'
        )
      }
    }
  },
}
</script>

<style lang="scss">
@import "@/assets/fonts/din-cyr";

* {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  color: #575757;
  font-size: 16px;
  line-height: 1.4;
}

#app {
  font-family: "DIN Cyr";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

section {
  width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.header-with-menu {
  width: 100%;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 470px;
  grid-gap: 20px;

  h2 {
    margin: 0;
    padding: 0;
    font-size: 65px;
    font-weight: 900;
    line-height: 1;
    color: #E21E25;
  }

  .menu {
    width: 100%;
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;

    ul {
      margin: 0;
      padding: 0;

      li {
        padding: 8px 5px 10px;
        border-top: 2px solid transparent;
        border-bottom: 2px solid #12425B;
        list-style-type: none;
        font-weight: 500;
        font-size: 17px;
        color: #12425B;
        cursor: pointer;
        transition: all 0.4s ease;

        &:hover {
          padding: 8px 5px 10px 12px;
          background-color: #12425B;
          border-top-color: #12425B;
          border-bottom-color: #12425B;
          border-radius: 3px;
          box-shadow: 0 5px 10px 2px rgba(34, 60, 80, 0.3);
          color: white;
          transform: scale(110%);
        }

        &:last-of-type {
          border-bottom-color: transparent;
        }

        &:first-of-type {
          border-bottom-color: #12425B;
        }
      }

      &:last-child {
        border-bottom-color: transparent;
      }
    }
  }
}

h3 {
  font-size: 60px;
  font-weight: 400;
  color: #727270;
}

h4 {
  font-size: 35px;
  font-weight: 700;
  color: #727270;
}

.to-top {
  opacity: 0;
  z-index: 101;
  width: 46px;
  height: 46px;
  position: fixed;
  bottom: 0px;
  right: 10px;
  cursor: pointer;
  border: 3px solid #BFE2FF;
  transition: all .5s ease-in-out;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: #337AB7;
  background-size: 50% auto;
  background-image: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTYuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjMycHgiIGhlaWdodD0iMzJweCIgdmlld0JveD0iMCAwIDQ0NC44MTkgNDQ0LjgxOSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNDQ0LjgxOSA0NDQuODE5OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxnPgoJPHBhdGggZD0iTTQzMy45NjgsMjc4LjY1N0wyNDguMzg3LDkyLjc5Yy03LjQxOS03LjA0NC0xNi4wOC0xMC41NjYtMjUuOTc3LTEwLjU2NmMtMTAuMDg4LDAtMTguNjUyLDMuNTIxLTI1LjY5NywxMC41NjYgICBMMTAuODQ4LDI3OC42NTdDMy42MTUsMjg1Ljg4NywwLDI5NC41NDksMCwzMDQuNjM3YzAsMTAuMjgsMy42MTksMTguODQzLDEwLjg0OCwyNS42OTNsMjEuNDExLDIxLjQxMyAgIGM2Ljg1NCw3LjIzLDE1LjQyLDEwLjg1MiwyNS42OTcsMTAuODUyYzEwLjI3OCwwLDE4Ljg0Mi0zLjYyMSwyNS42OTctMTAuODUyTDIyMi40MSwyMTMuMjcxTDM2MS4xNjgsMzUxLjc0ICAgYzYuODQ4LDcuMjI4LDE1LjQxMywxMC44NTIsMjUuNywxMC44NTJjMTAuMDgyLDAsMTguNzQ3LTMuNjI0LDI1Ljk3NS0xMC44NTJsMjEuNDA5LTIxLjQxMiAgIGM3LjA0My03LjA0MywxMC41NjctMTUuNjA4LDEwLjU2Ny0yNS42OTNDNDQ0LjgxOSwyOTQuNTQ1LDQ0MS4yMDUsMjg1Ljg4NCw0MzMuOTY4LDI3OC42NTd6IiBmaWxsPSIjRkZGRkZGIi8+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPC9zdmc+Cg==);
}
</style>
