<template>
  <div id="values">
    <h3>Ценности</h3>
    <div class="values-box">
      <div class="value-item">
        <div class="name">
          ЗДОРОВЬЕ
        </div>
        <div class="description">
          Наша ценность – здоровые семьи и здоровый образ жизни.
        </div>
      </div>
      <div class="value-item">
        <div class="name">
          КОМАНДА
        </div>
        <div class="description">
          Наша ценность – люди и взаимоотношения между ними. Команда единомышленников, стремящаяся к достижению цели, квалифицированные специалисты - эксперты своего дела. Мы ценим и поддерживаем инициативу своих сотрудников, дорожим мнением каждого. Мы даем нашим сотрудникам стабильность, перспективы и уверенность в завтрашнем дне.
        </div>
      </div>
      <div class="value-item">
        <div class="name">
          КЛИЕНТЫ
        </div>
        <div class="description">
          Наши клиенты – наша ценность.<br />
          Мы дорожим каждым своим клиентом.<br />
          Мы стремимся стать друзьями для наших клиентов.
        </div>
      </div>
      <div class="value-item">
        <div class="name">
          КАЧЕСТВО
        </div>
        <div class="description">
          Высокое качество нашего товара и услуг – неотъемлемое условие нашей работы. Мы ценим надежную репутацию нашей компании и поддерживаем её. Нам нравится то, чем мы занимаемся, мы любим своё дело, прикладываем максимум усилий для того чтобы оно имело успех. Мы профессионалы и всегда достигаем поставленных целей.
        </div>
      </div>
      <div class="value-item">
        <div class="name">
          ЧЕСТНОСТЬ
        </div>
        <div class="description">
          Мы честны и открыты в делах и мыслях.<br />
          Мы чувствуем свою ответственность за результаты нашей работы.<br />
          Мы всегда готовы прийти на помощь к своим коллегам в трудной ситуации.
        </div>
      </div>
      <div class="value-item">
        <div class="name">
          РАЗВИТИЕ
        </div>
        <div class="description">
          Своевременное обучение и развитие сотрудников – один из наших приоритетов. Мы дорожим своими прежними успехами, но постоянно стремимся к новым. Приверженность к интересам, стремлениям и целям компании. Цели компании – наши цели. Развитие корпоративной культуры имеет большое значение для роста и развития компании и ее сотрудников.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BrandValues"
}
</script>

<style lang="scss">
#values {
  width: 100%;
  margin-top: 95px;

  h3 {
    margin-bottom: 55px;
  }

  .values-box {
    width: 100%;
    display: grid;
    grid-template-rows: repeat(6, 1fr);
    grid-template-columns: 1fr;
    grid-gap: 20px;

    .value-item {
      display: grid;
      grid-template-rows: 1fr;
      grid-template-columns: 350px 1fr;
      grid-gap: 20px;

      .name {
        font-size: 55px;
        font-weight: 700;
        line-height: 1;
        color: #B8CBD7;
      }

      .description {
        padding-top: 5px;
      }
    }
  }

}
</style>
