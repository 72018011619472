<template>
  <div id="audience-consumption-moments">
    <h3>Моменты потребления</h3>
    <p>Мы считаем, что нашим клиентам нужны не запчасти, а исправный автомобиль. Поэтому стараемся предоставить сразу комплекс услуг автовладельцу.</p>
    <p>В большинстве случаев есть 2 причины, по которым автовладельцы обращаются к нам: плановое ТО, либо неожиданная поломка.</p>
    <p>При плановом ТО клиентам важно: качество и стоимость запчастей, масел и выполняемых работ.</p>
    <p>При неожиданных поломках: на первый план выходит скорость поставки запчастей и выполнения услуг по ремонту, поэтому своевременные поставки без задержек это очень важно.</p>
    <p class="header">Торговые залы</p>
    <p>Наши магазины всегда чистые и выполнены в современном дизайне, доступен бесплатный кофе и удобная зона ожидания. Даже в небольших торговых залах мы стремимся создать комфортные условия для посетителей.</p>
    <p>Выкладка частично открытая для того, чтобы потребитель смог взять товар в руки.</p>
  </div>
</template>

<script>
export default {
  name: "AudienceConsumptionMoments"
}
</script>

<style lang="scss">
#audience-consumption-moments {
  width: 100%;

  h3 {
    margin-top: 80px;
  }

  p {
    width: 100%;
    padding-right: 35%;

    &.header {
      font-weight: 700;
    }
  }
}
</style>
