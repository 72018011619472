<template>
  <div id="design-services-logos-workshop">
    <p class="header">Материал готовится к публикации.</p>
  </div>
</template>

<script>
export default {
  name: "DesignServicesLogosWorkshop"
}
</script>

<style lang="scss">
#design-services-logos-workshop {
  width: 100%;

  &>p {
    width: 100%;
    padding-right: 25%;

    &.header {
      font-weight: 700;
      font-size: 20px;
      padding-right: 30%;
    }
  }
}
</style>
